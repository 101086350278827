//
//
//
//
//
//
//
//
//
//
//
//

import head from '@nextory/components/mixins/head'
import tracker from '@nextory/components/mixins/tracker'

export default {
  mixins: [head, tracker],
}
