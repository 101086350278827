//
//
//
//

import head from '@nextory/components/mixins/head'
import tracker from '@nextory/components/mixins/tracker'

export default {
  mixins: [head, tracker],

  props: {
    error: { type: Object, default: null },
  },
}
