import { AccountSubscription } from '../../api-types/AccountSubscription'
import { PaymentInfos, Subscription } from '../../legacy-api/types/subscription'

export function subscriptionToPaymentInfo(
  nx5Subscription: AccountSubscription
): PaymentInfos[] {
  if (nx5Subscription.payment_infos?.card_details) {
    const modernCardDetails = nx5Subscription.payment_infos?.card_details
    const legacyCardDetails: PaymentInfos = {
      id: nx5Subscription.id,
      last_four_digits: modernCardDetails.last_four_digits,
      // legacy format: 'YYYY-MM-DD'
      credit_card_expired_at:
        modernCardDetails.expiry_year +
        '-' +
        modernCardDetails.expiry_month +
        '-30',
    }
    return [legacyCardDetails]
  }
  return []
}

export function modernAccountSubscriptionToLegacySubscription(
  modernSubscription: AccountSubscription
): Subscription {
  return {
    id: modernSubscription.id,
    allow_recovery: false,
    offer: {
      amount: String(modernSubscription.offer.amount),
      initial_amount: String(modernSubscription.offer.amount),
      currency: modernSubscription.offer.currency,
      description: '',
      engagement_duration: modernSubscription.offer.period,
      id: modernSubscription.offer.id,
      label: '',
      number_of_days_before_first_payment: 0,
      number_of_days_before_offer_expiration: 0,
      number_of_initial_amount: 0,
      online_payment_url: '',
      periodicity: 'monthly',
      type: 'SUBSCRIPTION',
    },
    offer_public_name: modernSubscription.subscription_name,
    subscription_type: 'recurring',
    unsubscription_method: !modernSubscription.stop_date
      ? 'user_action'
      : 'external_action',
    start_date: modernSubscription.start_date,
    stop_date: modernSubscription.stop_date,
    renewal_date: modernSubscription.renewal_date,
    partner_name: '',
    payment_infos: subscriptionToPaymentInfo(modernSubscription)[0],
    renewal_amount: modernSubscription.renewal_amount,
  }
}

export function imitateFreeLegacyAccount(): Subscription {
  return {
    id: 0,
    allow_recovery: false,
    offer: {
      amount: '0',
      initial_amount: '0',
      currency: 'EUR',
      description: '',
      engagement_duration: 0,
      id: 0,
      label: '',
      number_of_days_before_first_payment: 0,
      number_of_days_before_offer_expiration: 0,
      number_of_initial_amount: 0,
      online_payment_url: '',
      periodicity: 'monthly',
      type: 'SUBSCRIPTION',
    },
    offer_public_name: '',
    subscription_type: 'recurring',
    unsubscription_method: 'user_action',
    start_date: '',
    stop_date: null,
    renewal_date: '',
    partner_name: '',
    payment_infos: null,
    renewal_amount: 0,
  }
}
