import { AxiosPromise } from 'axios'

import { Order } from '../api-types/Order'
import BaseApi from '../bridge-api/baseApi'

export class Orders extends BaseApi {
  getOrders(): AxiosPromise<Order[]> {
    return this.http({
      method: 'get',
      url: `/order/v1/me/orders`,
    })
  }
}
