import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'

export default class Users extends BaseApi {
  /**
   * Send a reset password email to a user
   */
  resetPassword(data: {
    email: string // user email to send reset password email to
  }): AxiosPromise<{
    message: string // Confirmation message
  }> {
    return this.http({
      method: 'post',
      url: `/reset_password`,
      data,
    })
  }

  /**
   * Mock NX5 response
   */
  isValidResetPasswordRequest(data: {
    hash: string
    user_id: Int32Array
  }): Promise<{ status: Number }> {
    return new Promise(resolve => {
      resolve({
        status: 204,
      })
    })
  }

  /**
   * Action that update user password after receiving email
   */
  updateResetPassword(data: {
    hash: string // the modern hash from the received email url
    password: string // the new password to update
    password_confirmation: string // the confirmation of the new password
    token: string // the legacy token
    user_id: number // the modern user_id from the received email url
  }): AxiosPromise<{
    message: string // Confirmation message
  }> {
    return this.http({
      method: 'patch',
      url: `/reset_password`,
      data: {
        password: data.password,
        password_confirmation: data.password_confirmation,
        token: data.token,
      },
    })
  }
}
