import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { Coupon } from './types/coupon'

export default class Tunnels extends BaseApi {
  /**
   * Get the gateway and offer information from a coupon code
   */
  getCode(
    code: string // eg. "FREE4EVER"
  ): AxiosPromise<Coupon> {
    return this.send('get', `/tunnels/${code}`)
  }
}
