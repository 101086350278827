export const HeadingHome = () => import('../../components/HeadingHome.vue' /* webpackChunkName: "components/heading-home" */).then(c => wrapFunctional(c.default || c))
export const LaunchAppUnderwayFaq = () => import('../../components/LaunchAppUnderwayFaq.vue' /* webpackChunkName: "components/launch-app-underway-faq" */).then(c => wrapFunctional(c.default || c))
export const LaunchAppUnderwayFaqQuestion = () => import('../../components/LaunchAppUnderwayFaqQuestion.vue' /* webpackChunkName: "components/launch-app-underway-faq-question" */).then(c => wrapFunctional(c.default || c))
export const LaunchAppUnderwayHeroBanner = () => import('../../components/LaunchAppUnderwayHeroBanner.vue' /* webpackChunkName: "components/launch-app-underway-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const LaunchAppUnderwaySlider = () => import('../../components/LaunchAppUnderwaySlider.vue' /* webpackChunkName: "components/launch-app-underway-slider" */).then(c => wrapFunctional(c.default || c))
export const LaunchAppUnderwayThreeQuestions = () => import('../../components/LaunchAppUnderwayThreeQuestions.vue' /* webpackChunkName: "components/launch-app-underway-three-questions" */).then(c => wrapFunctional(c.default || c))
export const BooksList = () => import('../../components/booksList.vue' /* webpackChunkName: "components/books-list" */).then(c => wrapFunctional(c.default || c))
export const PopularBooks = () => import('../../components/popularBooks.vue' /* webpackChunkName: "components/popular-books" */).then(c => wrapFunctional(c.default || c))
export const StickyButton = () => import('../../components/stickyButton.vue' /* webpackChunkName: "components/sticky-button" */).then(c => wrapFunctional(c.default || c))
export const AlertMessage = () => import('../../../../packages/nx-components/src/AlertMessage.vue' /* webpackChunkName: "components/alert-message" */).then(c => wrapFunctional(c.default || c))
export const CrossLink = () => import('../../../../packages/nx-components/src/CrossLink.vue' /* webpackChunkName: "components/cross-link" */).then(c => wrapFunctional(c.default || c))
export const IllustratedDetails = () => import('../../../../packages/nx-components/src/IllustratedDetails.vue' /* webpackChunkName: "components/illustrated-details" */).then(c => wrapFunctional(c.default || c))
export const NxBg = () => import('../../../../packages/nx-components/src/NxBg.vue' /* webpackChunkName: "components/nx-bg" */).then(c => wrapFunctional(c.default || c))
export const NxBgPicture = () => import('../../../../packages/nx-components/src/NxBgPicture.vue' /* webpackChunkName: "components/nx-bg-picture" */).then(c => wrapFunctional(c.default || c))
export const NxBlurhashImg = () => import('../../../../packages/nx-components/src/NxBlurhashImg.vue' /* webpackChunkName: "components/nx-blurhash-img" */).then(c => wrapFunctional(c.default || c))
export const NxCheckbox = () => import('../../../../packages/nx-components/src/NxCheckbox.vue' /* webpackChunkName: "components/nx-checkbox" */).then(c => wrapFunctional(c.default || c))
export const NxFooter = () => import('../../../../packages/nx-components/src/NxFooter.vue' /* webpackChunkName: "components/nx-footer" */).then(c => wrapFunctional(c.default || c))
export const NxHeader = () => import('../../../../packages/nx-components/src/NxHeader.vue' /* webpackChunkName: "components/nx-header" */).then(c => wrapFunctional(c.default || c))
export const NxImg = () => import('../../../../packages/nx-components/src/NxImg.vue' /* webpackChunkName: "components/nx-img" */).then(c => wrapFunctional(c.default || c))
export const NxInput = () => import('../../../../packages/nx-components/src/NxInput.vue' /* webpackChunkName: "components/nx-input" */).then(c => wrapFunctional(c.default || c))
export const NxLoader = () => import('../../../../packages/nx-components/src/NxLoader.vue' /* webpackChunkName: "components/nx-loader" */).then(c => wrapFunctional(c.default || c))
export const NxNarrowContainer = () => import('../../../../packages/nx-components/src/NxNarrowContainer.vue' /* webpackChunkName: "components/nx-narrow-container" */).then(c => wrapFunctional(c.default || c))
export const NxOffers = () => import('../../../../packages/nx-components/src/NxOffers.vue' /* webpackChunkName: "components/nx-offers" */).then(c => wrapFunctional(c.default || c))
export const PaymentForm = () => import('../../../../packages/nx-components/src/PaymentForm.vue' /* webpackChunkName: "components/payment-form" */).then(c => wrapFunctional(c.default || c))
export const PopIn = () => import('../../../../packages/nx-components/src/PopIn.vue' /* webpackChunkName: "components/pop-in" */).then(c => wrapFunctional(c.default || c))
export const SellingPoints = () => import('../../../../packages/nx-components/src/SellingPoints.vue' /* webpackChunkName: "components/selling-points" */).then(c => wrapFunctional(c.default || c))
export const StoreButtons = () => import('../../../../packages/nx-components/src/StoreButtons.vue' /* webpackChunkName: "components/store-buttons" */).then(c => wrapFunctional(c.default || c))
export const UserReview = () => import('../../../../packages/nx-components/src/UserReview.vue' /* webpackChunkName: "components/user-review" */).then(c => wrapFunctional(c.default || c))
export const ProfileAvatar = () => import('../../../../packages/nx-components/src/Profile/ProfileAvatar.vue' /* webpackChunkName: "components/profile-avatar" */).then(c => wrapFunctional(c.default || c))
export const AccordionItem = () => import('../../../../packages/nx-components/src/accordion/AccordionItem.vue' /* webpackChunkName: "components/accordion-item" */).then(c => wrapFunctional(c.default || c))
export const AccordionLink = () => import('../../../../packages/nx-components/src/accordion/AccordionLink.vue' /* webpackChunkName: "components/accordion-link" */).then(c => wrapFunctional(c.default || c))
export const Errors = () => import('../../../../packages/nx-components/src/errors/Errors.vue' /* webpackChunkName: "components/errors" */).then(c => wrapFunctional(c.default || c))
export const FlashMessage = () => import('../../../../packages/nx-components/src/flashMessages/FlashMessage.vue' /* webpackChunkName: "components/flash-message" */).then(c => wrapFunctional(c.default || c))
export const FlashMessages = () => import('../../../../packages/nx-components/src/flashMessages/FlashMessages.vue' /* webpackChunkName: "components/flash-messages" */).then(c => wrapFunctional(c.default || c))
export const HeaderFocusableNavigation = () => import('../../../../packages/nx-components/src/header/FocusableNavigation.vue' /* webpackChunkName: "components/header-focusable-navigation" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigation = () => import('../../../../packages/nx-components/src/header/Navigation.vue' /* webpackChunkName: "components/header-navigation" */).then(c => wrapFunctional(c.default || c))
export const HeaderStepHeader = () => import('../../../../packages/nx-components/src/header/StepHeader.vue' /* webpackChunkName: "components/header-step-header" */).then(c => wrapFunctional(c.default || c))
export const MixinsEscapable = () => import('../../../../packages/nx-components/src/mixins/escapable.js' /* webpackChunkName: "components/mixins-escapable" */).then(c => wrapFunctional(c.default || c))
export const MixinsLocalized = () => import('../../../../packages/nx-components/src/mixins/localized.js' /* webpackChunkName: "components/mixins-localized" */).then(c => wrapFunctional(c.default || c))
export const OfferPriceTag = () => import('../../../../packages/nx-components/src/offerPriceTag/OfferPriceTag.vue' /* webpackChunkName: "components/offer-price-tag" */).then(c => wrapFunctional(c.default || c))
export const OffersFeaturesList = () => import('../../../../packages/nx-components/src/offers/FeaturesList.vue' /* webpackChunkName: "components/offers-features-list" */).then(c => wrapFunctional(c.default || c))
export const OfferPriceTagMixinsOffersHelpers = () => import('../../../../packages/nx-components/src/offerPriceTag/mixins/offersHelpers.js' /* webpackChunkName: "components/offer-price-tag-mixins-offers-helpers" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
