import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { Profile } from './types/profile'

export default class Sessions extends BaseApi {
  login(
    data:
      | {
          identifier: string
          password: string
          provider?: 'password'
        }
      | {
          access_token: string
          provider:
            | 'digital_virgo'
            | 'facebook'
            | 'free'
            | 'sfr'
            | 'vitis'
            | 'external_token'
        }
      | {
          access_token: string
          provider: 'apple'
          user?: {
            accept_newsletter?: boolean
            email?: string
            first_name?: string
            last_name?: string
            username?: string
          }
        }
  ): AxiosPromise<Profile> {
    return this.http({
      method: 'post',
      url: `/sessions`,
      data,
    })
  }

  logout() {
    return this.http({
      method: 'delete',
      url: `/sessions`,
    })
  }
}
