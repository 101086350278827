import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { Author } from './types/author'
import { Book } from './types/book'
import { PaginationParams } from './types/common'
import { Series } from './types/series'

export default class Authors extends BaseApi {
  /**
   * Action that retrieve an author
   */
  fetchAuthor(
    authorId: number // id of author to get information
  ): AxiosPromise<Author> {
    return this.send('get', `/authors/${authorId}`)
  }

  /**
   * Action that retrieve an author's books
   */
  fetchAuthorBooks(
    authorId: number,
    params: PaginationParams
  ): AxiosPromise<Book[]> {
    return this.send('get', `/authors/${authorId}/books`, params)
  }

  /**
   * Returns series of an author
   */
  getAuthorSeries(
    authorId: number,
    params: PaginationParams
  ): AxiosPromise<Series[]> {
    return this.send('get', `/authors/${authorId}/series`, params)
  }
}
