import BaseApi from './baseApi'

export default class AdyenSubscription extends BaseApi {
  /**
   * Endpoint to create a subscription payment in Adyen
   */
  createSubscription(tmpData: {
    browserInfo: any // from Adyen, not explicitly typed
    code?: string
    paymentMethod: any // from Adyen, not explicitly typed
    riskData: any // from Adyen, not explicitly typed
    uuid: string
  }) {
    // TODO: Ask back-end team to ignore "brand" and "type"
    const { brand, type, ...paymentInfo } = tmpData.paymentMethod

    const data = {
      subscription_id: Number(tmpData.uuid),
      channel: 'NEXTORY',
      gateway: 'ADYEN',
      payment_info: paymentInfo,
      // TODO: It's going to be browser_info soon
      browserInfo: tmpData.browserInfo,
    }

    return this.http({
      method: 'post',
      url: '/obgateway/v1/register/checkout',
      data,
    })
  }
}
