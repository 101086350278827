//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import trustPaymentSymbol from '@nextory/assets/src/images/trust-payments-symbol.svg'

import CrossLink from './CrossLink.vue'

export default {
  components: {
    CrossLink,
  },

  props: {
    showNavigation: { type: Boolean, default: true },
  },

  data() {
    return {
      trustPaymentSymbol,
    }
  },

  methods: {
    currentYear() {
      return new Date().getFullYear()
    },

    clickCookie() {
      Cookiebot.show()
    },
  },
}
