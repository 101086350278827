import { AxiosPromise } from 'axios'

import LegacySession from '../legacy-api/sessions'
import { Profile as LegacyProfile } from '../legacy-api/types/profile'

import BaseApi from './baseApi'
import { modernAccountToLegacyProfile } from './converters/profileToLegacyProfile'
import convert from './helpers/convert'

export default class Sessions
  extends BaseApi
  // partially implementing this lets us use TypeScript's type inference on bridge methods
  implements Partial<LegacySession>
{
  login(
    data:
      | {
          identifier: string
          password: string
          provider?: 'password'
        }
      | {
          access_token: string
          provider:
            | 'digital_virgo'
            | 'facebook'
            | 'free'
            | 'sfr'
            | 'vitis'
            | 'external_token'
        }
      | {
          access_token: string
          provider: 'apple'
          user?: {
            accept_newsletter?: boolean
            email?: string
            first_name?: string
            last_name?: string
            username?: string
          }
        }
  ): AxiosPromise<LegacyProfile> {
    return convert(
      this.http({
        method: 'post',
        url: `/user/v1/sessions`,
        data,
      }),
      modernAccountToLegacyProfile
    )
  }

  logout() {
    return this.http({
      method: 'delete',
      url: `/user/v1/sessions`,
    })
  }
}
