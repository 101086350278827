//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Let us create link between projects on *shared* components.
 *
 * - If you're trying to link to a local route instead, use <nuxt-link>
 * - If you're trying to link to another project (eg. catalog->profile), use something like <a :href="$link.profile()">
 */
export default {
  name: 'CrossLink',

  props: {
    to: { type: String, default: undefined },
    name: { type: String, default: undefined },
    ariaLabel: { type: String, default: undefined },
  },

  computed: {
    localizedTo() {
      // If URL already starts with a locale, eg /fr/, skip $nxLocalePath
      if (this.to.startsWith(`/${this.$i18n.locale}/`)) {
        return this.to
      } else {
        return this.$nxLocalePath(this.to)
      }
    },
    externalUrl() {
      if (this.to.startsWith('/')) {
        return this.$link.absolute(this.to)
      } else {
        return this.to
      }
    },
  },
}
