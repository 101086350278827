import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { Book } from './types/book'
import { LanguagesParams, PaginationParams } from './types/common'
import { Selection } from './types/selection'

export default class Selections extends BaseApi {
  /**
   * Unused method. (actually, only used by an unused store method)
   */
  getSelections(params: PaginationParams): AxiosPromise<Selection[]> {
    return this.send('get', '/selections', params)
  }

  getSelection(selectionId: number): AxiosPromise<Selection> {
    return this.send('get', `/selections/${selectionId}`)
  }

  getSelectionBooks(
    selectionId: number,
    params: PaginationParams & LanguagesParams
  ): AxiosPromise<Book[]> {
    return this.send('get', `/selections/${selectionId}/books`, params)
  }
}
