import staticLinks from './static-links'

function trimPath(path) {
  if (path === '') return ''

  if (path.startsWith('?')) {
    // leave as-is, used by register codes, eg.: /fr/register?code=my-awesome-registration-code
    return path
  }

  // ensure all paths starts with a slash, eg. /<path> (relative URLs)
  return `/${path.replace(/^\//g, '')}`
}

function createLink(app, mapping, path) {
  return `/${app.i18n.locale}${mapping.directory}${trimPath(path)}`
}

export const mappings = {
  catalog: {
    directory: '/catalog',
    devHost: 'http://127.0.0.1:3303',
  },
  profile: {
    directory: '/profile',
    devHost: 'http://127.0.0.1:3300',
  },
  register: {
    directory: '/register',
    devHost: 'http://127.0.0.1:3302',
  },
  web: {
    directory: '',
    devHost: 'http://127.0.0.1:3301',
  },
}

// Dev URLs are removed on build-time
export function linkPlugin(app) {
  return {
    ...staticLinks,

    /**
     * Makes a relative URL absolute. Mainly used for callback parameters.
     *
     * Eg: /fr/register/card -> http://localhost:3302/fr/register/card
     *     /fr/profile       -> http://localhost:3300/fr/profile
     */
    absolute(path) {
      for (const key in mappings) {
        const mapping = mappings[key]
        const startMatch = `/${app.i18n.locale}${mapping.directory}`
        const isProduction = ['production', 'staging'].includes(
          process.env.ENVIRONMENT
        )

        if (path.startsWith(startMatch)) {
          let host = isProduction
            ? process.env.NEXTORY_WEB_URL
            : mapping.devHost

          // remove trailing slash from host
          if (host?.endsWith('/')) host = host.slice(0, -1)

          return host + path
        }

        if (!isProduction && path.startsWith(mapping.devHost + startMatch)) {
          // Special case in dev mode:
          //   If you're using $link.profile('/register/...'), you already have an absolute URL.
          //   In this case, we have nothing to do. 🤷
          return path
        }
      }

      /* eslint-disable no-console */
      console.warn(`Invalid path passed to $link.absolute(): ${path}`)

      return path
    },

    catalog(path = '') {
      return createLink(app, mappings.catalog, path)
    },

    profile(path = '') {
      return createLink(app, mappings.profile, path)
    },

    register(path = '') {
      return createLink(app, mappings.register, path)
    },

    web(path = '') {
      return createLink(app, mappings.web, path)
    },

    /**
     * @param {string} path
     * @returns {string|null}
     */
    getCrossLink(path) {
      const prefix = app.$config.nxLocalePathPrefix
      for (const locale of app.i18n.localeCodes) {
        if (path.startsWith(`/${locale}/`) || path === `/${locale}`) {
          path = path.slice(`/${locale}`.length, path.length)
          break
        }
      }

      if (prefix && path.startsWith(prefix)) {
        return null
      }

      const matchingMapping = Object.values(mappings).find(mapping => {
        return (
          !(prefix === '' && mapping.directory === '') &&
          path.startsWith(mapping.directory)
        )
      })

      if (matchingMapping) {
        return `${matchingMapping.devHost}/${app.i18n.locale}${path}`
      } else {
        return null
      }
    },

    isLocal(path) {
      return this.getCrossLink(path) === null
    },
  }
}

export default ({ app }, inject) => {
  inject('link', linkPlugin(app))
}
