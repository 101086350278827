import BaseApi from './baseApi'

export default class AdyenSubscription extends BaseApi {
  /**
   * @deprecated Use Subscription/PostSubscription instead
   *
   * Add endpoint to create a subscription payment in Adyen to make user premium
   */
  createSubscription(data: {
    browserInfo: object // from Adyen
    code?: string
    paymentMethod: object // from Adyen
    riskData: object // from Adyen
    token: string // Recaptcha V3 token
    uuid: string
  }) {
    return this.http({
      method: 'post',
      url: '/transactions/adyen',
      data,
    })
  }
}
