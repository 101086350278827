import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0a5172be = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _49c12848 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _4181c4e6 = () => interopDefault(import('../pages/download-apps.vue' /* webpackChunkName: "pages/download-apps" */))
const _790b7738 = () => interopDefault(import('../pages/kiosk.vue' /* webpackChunkName: "pages/kiosk" */))
const _4b1e5bd8 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _0bd066e9 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _18619346 = () => interopDefault(import('../pages/user-terms.vue' /* webpackChunkName: "pages/user-terms" */))
const _e8518750 = () => interopDefault(import('../pages/in-app/launch.vue' /* webpackChunkName: "pages/in-app/launch" */))
const _77dd3348 = () => interopDefault(import('../pages/launch/nextory-app.vue' /* webpackChunkName: "pages/launch/nextory-app" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'link--active',
  linkExactActiveClass: 'link--exact',
  scrollBehavior,

  routes: [{
    path: "/fr",
    component: _0a5172be,
    name: "index___fr"
  }, {
    path: "/fr-en",
    component: _0a5172be,
    name: "index___fr-en"
  }, {
    path: "/fr-en/about",
    component: _49c12848,
    name: "about___fr-en"
  }, {
    path: "/fr-en/download-apps",
    component: _4181c4e6,
    name: "download-apps___fr-en"
  }, {
    path: "/fr-en/kiosk",
    component: _790b7738,
    name: "kiosk___fr-en"
  }, {
    path: "/fr-en/privacy-policy",
    component: _4b1e5bd8,
    name: "privacy-policy___fr-en"
  }, {
    path: "/fr-en/search",
    component: _0bd066e9,
    name: "search___fr-en"
  }, {
    path: "/fr-en/user-terms",
    component: _18619346,
    name: "user-terms___fr-en"
  }, {
    path: "/fr/about",
    component: _49c12848,
    name: "about___fr"
  }, {
    path: "/fr/download-apps",
    component: _4181c4e6,
    name: "download-apps___fr"
  }, {
    path: "/fr/kiosk",
    component: _790b7738,
    name: "kiosk___fr"
  }, {
    path: "/fr/privacy-policy",
    component: _4b1e5bd8,
    name: "privacy-policy___fr"
  }, {
    path: "/fr/search",
    component: _0bd066e9,
    name: "search___fr"
  }, {
    path: "/fr/user-terms",
    component: _18619346,
    name: "user-terms___fr"
  }, {
    path: "/fr-en/in-app/launch",
    component: _e8518750,
    name: "in-app-launch___fr-en"
  }, {
    path: "/fr-en/launch/nextory-app",
    component: _77dd3348,
    name: "launch-nextory-app___fr-en"
  }, {
    path: "/fr/in-app/launch",
    component: _e8518750,
    name: "in-app-launch___fr"
  }, {
    path: "/fr/launch/nextory-app",
    component: _77dd3348,
    name: "launch-nextory-app___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
