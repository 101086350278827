import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { PaginationParams } from './types/common'
import { EditorialEntry } from './types/editorialEntry'

export default class Edito extends BaseApi {
  getEntries(params: PaginationParams): AxiosPromise<EditorialEntry[]> {
    return this.send('get', '/editorial_entries', params)
  }
}
