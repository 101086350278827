import { Subscription } from '@nextory/hummingbird/src/api-types/Subscription'
import { SubscriptionVariant } from '@nextory/hummingbird/src/api-types/SubscriptionVariant'
import { DeviceOpportunity } from '@nextory/hummingbird/src/legacy-api/types/deviceOpportunity'
import consola from 'consola'
const logger = consola.withScope('State - Opportunities')

const state = () => ({
  opportunities: {},
  orderedOpportunities: {},
  getIdByUuid: {},
  selectedOpportunity: undefined,
  recaptchaToken: undefined,
})

const mutations = {
  SET_OPPORTUNITIES(state, opportunities) {
    state.opportunities = opportunities
  },

  SET_ORDERED_OPPORTUNITIES(state, opportunities) {
    state.orderedOpportunities = opportunities
  },

  SET_ID_BY_UUIDS(state, obj) {
    state.getIdByUuid = obj
  },

  SET_SELECTED_UUID(state, uuid) {
    state.selectedOpportunity = uuid.toString()
  },

  SET_RECAPTCHA_TOKEN(state, token) {
    state.recaptchaToken = token
  },

  RESET_UUID(state) {
    state.selectedOpportunity = undefined
  },
}

const actions = {
  async getAvailableOpportunities({ commit, dispatch, state }) {
    if (Object.keys(state.opportunities).length === 0) {
      try {
        let response =
          await this.$hummingbird.deviceOpportunities.getDeviceOpportunities({
            multi_screen: this.$i18n.t(
              'regfunnel.plan_picker.list.simultaneous_devices_title'
            ),
            time_credit: this.$i18n.t(
              'regfunnel.plan_picker.list.audiobooks_title'
            ),
            feature_price_label: this.$i18n.t('features.price'),
          })

        commit('SET_OPPORTUNITIES', response.data)

        const family = response.data.subscriptions.find(
          sub => sub.alias === 'FAMILY'
        )

        const bestOffer =
          family?.variants[0].subscription_id ||
          this.$config.NEXTORY_BEST_OFFER_UUID

        commit('SET_SELECTED_UUID', bestOffer)
      } catch (err) {
        logger.error(err)
      }
    }
    return state.opportunities
  },

  async getOpportunityByUuid({ dispatch, state }, uuid) {
    if (state.opportunities.length === 0) {
      await dispatch('getAvailableOpportunities')
    }
    if (!uuid) uuid = state.selectedOpportunity

    /** @type {Subscription} */
    const subscription = state.opportunities.subscriptions.find(s =>
      s.variants.some(v => v.subscription_id.toString() === uuid)
    )
    /** @type {SubscriptionVariant} */
    const variant = subscription?.variants.find(
      v => v.subscription_id.toString() === uuid
    )

    if (!variant) {
      throw new Error('This Opportunity UUID Does Not Exist!')
    }

    /**
     * FIXME: We're recreating a DeviceOpportunity to make it look like the legacy API here.
     *        This is a temporary fix until the /card page is redone with the new API,
     *        it will need to be removed.
     *
     * @type {DeviceOpportunity}
     */
    const legacyOffer = {
      background_primary_color: null,
      background_secondary_color: null,
      features: [],
      gateway: {
        payment_provider: 'ADYEN',
        offer: {
          number_of_days_before_first_payment: subscription.trial_days,
          currency: 'EUR',
          amount: variant.amount,
          id: variant.subscription_id,
        },
        uuid: variant.subscription_id,
      },
      id: variant.subscription_id,
      name: variant.plan_name,
      selection: null,
      text_color: '',
    }

    return legacyOffer
  },
}

const getters = {
  getOpportunities(state) {
    return state.opportunities
  },

  getSelectedUuid(state) {
    return state.selectedOpportunity
  },

  /**
   * FIXME: Recaptcha-stuff probably shouldn't be in this file
   */
  getRecaptchaToken(state) {
    return state.recaptchaToken
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
