import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { PaginationParams } from './types/common'
import { Showroom } from './types/showroom'

export default class Showrooms extends BaseApi {
  getShowrooms(
    params: {
      context?: string
    } & PaginationParams
  ): AxiosPromise<Showroom[]> {
    return this.send('get', '/showrooms', params)
  }
}
