//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// TODO: Should be renamed to something else.
// @see https://nextory.atlassian.net/browse/NXWEB-3189

export default {
  props: {
    error: { type: Object, default: null },
  },

  computed: {
    is404() {
      return this.error.statusCode === 404
    },

    errorTitle() {
      return this.is404 ? this.$t('404.title') : this.$t('global.error.title')
    },

    errorBody() {
      return this.is404 ? this.$t('404.body') : this.error.message
    },

    ctaUrl() {
      return this.error.url ? this.error.url : this.$link.catalog()
    },

    ctaText() {
      return this.error.cta
        ? this.error.cta
        : this.$t('404.button._browse_books')
    },
  },
}
