import NxSignature from '@nextory/signature'
import axios, { AxiosInstance, Method } from 'axios'
import consola from 'consola'

// @ts-ignore
import { version } from '~/package'

import { RequestInterface } from '../interfaces/request'

const logger = consola.withScope('Hummingbird (Youboox - Legacy)')

class Request implements RequestInterface {
  private language: string | undefined
  private country: string | undefined
  private adultFilter: boolean
  private authToken: string | null
  private logLevel: number
  private crypto: NxSignature
  http: AxiosInstance
  private authorizationToken: string | undefined // Used by Digital Virgo
  private catalogId: number | undefined
  private virtualCatalogIds: string

  constructor(
    private apiKey: string,
    private apiSecret: string,
    private baseURL: string,
    private userAgent: string,
    language: string | undefined = 'fr-FR',
    country: string | undefined = undefined
  ) {
    this.language = language
    this.country = country
    this.adultFilter = true
    this.virtualCatalogIds = ''

    this.authToken = null
    this.logLevel = 3

    this.crypto = new NxSignature(this.apiKey, this.apiSecret)
    this.http = this.init()
    this.setInterceptors()
  }

  _setLanguage(language: string) {
    this.language = language.substring(0, 2)
    this.http.defaults.headers['X-API-Language'] = this.language
    logger.debug(
      'Setting `X-API-Language` headers to',
      '`' + this.language + '`'
    )
  }

  _setCountry(country: string | undefined) {
    this.country = country

    if (this.hasCountry()) {
      logger.debug('Setting `X-API-Country` headers to', '`' + country + '`')
      this.http.defaults.headers['X-API-Country'] = country
    } else {
      delete this.http.defaults.headers['X-API-Country']
    }
  }

  _setApiKey(apiKey: string) {
    this.apiKey = apiKey
    this.http.defaults.headers['X-API-Key'] = apiKey
    this.crypto.apiKey = this.apiKey
  }

  _setApiSecret(apiSecret: string) {
    this.apiSecret = apiSecret
    this.crypto.apiSecret = this.apiSecret
  }

  _setAuthToken(authToken: string | null) {
    this.authToken = authToken

    if (authToken) {
      this.http.defaults.headers['X-API-AUTH-TOKEN'] = authToken
    }
  }

  _setAuthorizationToken(authorizationToken: string) {
    this.authorizationToken = authorizationToken

    if (authorizationToken) {
      this.http.defaults.headers.Authorization = `Token token=${authorizationToken}`
    }
  }

  _resetAuthorizationToken() {
    this.authorizationToken = undefined

    this.http.defaults.headers.Authorization = null
  }

  _setLogLevel(logLevel: number) {
    this.logLevel = logLevel

    logger.level = logLevel
  }

  _setUserCatalogIds(catalogId: number, virtualCatalogIds: number[]) {
    this.catalogId = catalogId
    this.virtualCatalogIds = virtualCatalogIds.join('_')
  }

  _setAdultFilter(adultFilter: boolean) {
    this.adultFilter = adultFilter
  }

  hasCountry() {
    return !!this.country
  }

  hasCatalogId() {
    return !!this.catalogId
  }

  hasVirtualCatalogId() {
    return this.virtualCatalogIds.length > 0
  }

  init() {
    const headers = {
      Accept: 'application/fr.youboox',
      'Content-Type': 'application/json',

      'X-API-Key': this.apiKey,
      'X-API-Device-Type': 'WEB',
      'X-API-VERSION': '1',
      'X-API-Language': this.language,
      'X-API-User-Agent': `${this.userAgent} v${version}`,
    } as { [key: string]: string | undefined }

    if (this.hasCountry()) {
      headers['X-API-Country'] = this.country
    }

    logger.debug('Hummingbird headers (legacy):', headers)

    return axios.create({
      baseURL: this.baseURL,
      headers,
    })
  }

  buildUrl(url: string) {
    return `${this.baseURL}/${url}`
  }

  /**
   * Build unique key for cache
   */
  buildCacheKey(endpoint: string, params: object) {
    return (
      endpoint +
      this.apiKey +
      this.language +
      (this.hasCountry() ? this.country : '') +
      (this.hasCatalogId() ? `c_${this.catalogId}_` : '') +
      (this.hasVirtualCatalogId() ? `vc_${this.virtualCatalogIds}_` : '') +
      `af_${this.adultFilter}_` +
      (typeof params === 'undefined' ? '' : JSON.stringify(params))
    )
  }

  /**
   * The method handle the API call and create a cachable object
   */
  async apiCall(method: Method, endpoint: string, params: object) {
    // When the data is not found in the cache then we can make request to the server
    const req = await this.http({
      method,
      url: endpoint,
      params,
    })

    // Create a cachable object for subsequent request
    const res = JSON.stringify({
      data: req.data,
      headers: req.headers,
    })

    return new Promise(function (resolve) {
      resolve(JSON.parse(res))
    })
  }

  /**
   * Method used to send apiv2 request
   * The method return apiv2 or cache answer, depending on method
   */
  send(method: Method, endpoint: string, params: object = {}) {
    return this.apiCall(method, endpoint, params)
  }

  /**
   * Use to set request, response and error interceptors
   * It adds the +X-API-Timestamp+ and +X-API-Signature+ headers to the request
   */
  setInterceptors() {
    this.http.interceptors.request.use(
      config => {
        // Workaround to inject the value dynamically
        // @see https://github.com/axios/axios/issues/1476#issuecomment-542958459
        config.params = config.params || {}
        config.params.is_adult_filtered = this.adultFilter

        const timestamp = (Date.now() / 1000) | 0 // Same as Math.floor(Date.now() / 1000) but faster

        config.headers['X-API-Timestamp'] = timestamp
        const signature = this.crypto.generateSignature(
          timestamp,
          config.url || '',
          config.params,
          config.data
        )
        config.headers['X-API-Signature'] = signature

        logger.debug(
          'Making request to',
          [config.baseURL, config.url].join(''),
          config.params !== undefined
            ? `with params ${JSON.stringify(config.params)}`
            : ''
        )

        return config
      },
      error => {
        if (error.message === 'Request aborted') {
          return true
        }

        logger.error('Request error:', this._formattedError(error))

        return Promise.reject(error)
      }
    )

    this.http.interceptors.response.use(
      response => {
        return response
      },
      error => {
        let errorMessage: string | undefined

        if (error.response) {
          if (error.response.data) {
            errorMessage = `[${error.response.status}] ${error.response.data.message}`
          } else if (error.response.status && error.response.statusText) {
            errorMessage = `[${error.response.status}] ${error.response.statusText}`
          }
        }

        if (error.request.method && error.request.path) {
          // Append the method and path to the error message, if available
          errorMessage += ` (${error.request.method} ${error.request.path})`
        }

        // eg. [500] Internal Server Error (GET /media_types)
        logger.error('Response error:', errorMessage)

        return Promise.reject(error)
      }
    )
  }

  _formattedError(error: Error) {
    if (this.logLevel <= 3) {
      // Production
      return JSON.stringify(error.message)
    } else {
      // Development / staging
      delete error.stack
      return JSON.stringify(error)
    }
  }
}

export default Request
