/* eslint-disable */
/* This is a generated file. */
import loader from '../src/loader'
export default loader('fr_FR', {
  "404.body": "Nous n'avons pas réussi à trouver la page que vous cherchez, mais voici un chat qui lit un livre.",
  "404.button._browse_books": "Afficher les livres",
  "404.cta": "Retourner sur la page d'accueil",
  "404.title": "Cette page n'existe pas",
  "about.our_story.body": "Nextory a été fondée en 2015 à Stockholm dans le but de rendre la lecture plus accessible en proposant une nouvelle expérience aux lecteurs. Ces dernières années, la technologie a transformé le monde dans lequel nous vivons, et notre projet fait plus que jamais sens : nous voulons enrichir le quotidien des gens au travers de la lecture et contribuer ainsi à un bien-être accru, au développement personnel, au renforcement de l’ouverture d’esprit et in fine de la démocratie.\n\nAujourd'hui, Nextory est l'un des premiers services d'abonnement de livres audio, de livres et de magazines numériques en Europe. Avec Nextory, il est très simple de trouver le bon livre, sur votre téléphone ou votre tablette. Vous pouvez choisir parmi des milliers d'histoires.\n\nVos livres favoris sont toujours à portée de main, que ce soit pendant une séance de sport, un voyage, la préparation d'un bon repas ou même pour vous relaxer. Choisissez ce que vous souhaitez écouter ou lire, ou laissez-vous inspirer par nos suggestions de lecture.",
  "about_page.title_other_markets": "Nextory dans le monde",
  "app.mypages.account.edit_sub.error.broken_token.body": "Pour modifier votre abonnement, connectez-vous au site internet de Nextory et rendez-vous sur Mon compte.",
  "app.mypages.account.edit_sub.error.broken_token.title": "Il semblerait que ce lien ne fonctionne plus",
  "book_screen.meta.price.legal_disclaimer": "La consultation de ce titre est comprise dans votre abonnement. À titre d’information, le prix de location fixé par l’éditeur est de {priceTotal} (soit {pricePerPage} par page lue).",
  "bookpage.hero.logged_out.title": "Lire et écouter \n gratuitement pendant {trialDays} jours.",
  "campaign.collect_points.funnel.error.user_illegible": "Vous ne pouvez pas utiliser cette campagne.",
  "campaign.collect_points.funnel.error.wrong_identifyer": "Il y a un problème. Réessayez.",
  "campaign.collect_points.funnel.logged_out.body": "Entrez votre mot de passe pour commencer à obtenir des {unit} tous les mois.",
  "campaign.collect_points.funnel.logged_out.success.body": "Vous pouvez gérer vos paramètres dans Mon compte.",
  "campaign.collect_points.funnel.logged_out.success.title": "Profitez de vos {unit} !",
  "campaign.collect_points.funnel.new_user.title": "Créer un compte pour continuer",
  "campaign.collect_points.funnel.offer": "Obtenez {amount} {unit} par mois",
  "campaign.collect_points.funnel.payment": "Vous collecterez {amount} {unit} par mois.",
  "campaign.collect_points.funnel.sub_picker.points_amount": "Des points coop mensuels",
  "campaign.collect_points.funnel.title": "C'est bon de vous revoir !",
  "campaign.regfunnel.payment.summary.free_days_and_cancel_date": "{price} par mois à partir du {date} – annulez avant pour ne rien payer.",
  "cancellation.funnel.success.title": "Votre abonnement a été annulé",
  "cancellation_flow.cancelled_subscription.winback.action.use_offer_now": "Je veux utiliser l’offre directement",
  "cancellation_flow.cancelled_subscription.winback.button.copy_code": "Copier le code",
  "cancellation_flow.cancelled_subscription.winback.card.body": "Lorsque vous voudrez recommencer à lire, saisissez ce code pour obtenir vos {trialDays} jours gratuits.",
  "cancellation_flow.cancelled_subscription.winback.card.title": "Vous nous manquez déjà !",
  "cancellation_funnel.action_go_back": "← Retour à Mon compte",
  "cancellation_funnel.active_user.microcopy_date": "Vous pourrez lire et écouter jusqu'au {date}.",
  "cancellation_funnel.cancel_trial.cancel.card.body": "Nous espérons vous revoir bientôt ! Attention : vous ne pourrez plus lire ou écouter une fois votre essai gratuit annulé.",
  "cancellation_funnel.cancel_trial.card.title": "Annuler l’essai gratuit",
  "cancellation_funnel.canceled_subscription.success.title": "Votre abonnement a été supprimé",
  "cancellation_funnel.disclaimer.body": "En annulant votre abonnement, vous annulez aussi votre essai gratuit. Vous ne pourrez plus lire ou écouter de livres.",
  "cancellation_funnel.disclaimer.error_box_not_ticked": "Cochez la case pour continuer.",
  "cancellation_funnel.disclaimer_checkbox": "Je comprends.",
  "cancellation_funnel.downgrade.card.button": "Passer à Standard",
  "cancellation_funnel.downgrade.title": "Vous ne lisez pas tant que ça ? L’abonnement {name} est parfait pour vous !",
  "cancellation_funnel.extend_trial.extend.card.body": "Si vous n’avez pas encore trouvé le temps pour lire ou écouter, vous pouvez prolonger votre essai gratuit de {days} jours.",
  "cancellation_funnel.extend_trial.extend.card.button": "Prolonger l’essai gratuit",
  "cancellation_funnel.extend_trial.extend.card.title": "Prolonger l’essai gratuit",
  "cancellation_funnel.extend_trial.headline": "Vous n’avez pas trouvé le temps pour lire ou écouter ?",
  "cancellation_funnel.reason1": "Je lis par périodes et je serai bientôt de retour",
  "cancellation_funnel.reason2": "Cette application est difficile à utiliser",
  "cancellation_funnel.reason3": "J'utilise un service différent",
  "cancellation_funnel.reason4": "Je trouve Nextory trop cher",
  "cancellation_funnel.reason5": "L'abonnement ne me convient pas",
  "cancellation_funnel.reason6": "Je n'arrive pas à lire sur l'appareil de mon choix",
  "cancellation_funnel.reason7": "Le contenu n'était pas à la hauteur de mes attentes",
  "cancellation_funnel.reason8": "Autre",
  "cancellation_funnel.revert_cancel.cancel.card.body": "Nous garderons votre bibliothèque intacte. Ainsi, vous pourrez revenir et recommencer à lire ou à écouter quand vous le souhaitez.",
  "cancellation_funnel.revert_cancel.card.button": "Rester un mois de plus",
  "cancellation_funnel.revert_cancel.extend.success.body": "Vous pouvez lire et écouter gratuitement jusqu'au {date}.",
  "cancellation_funnel.revert_cancel.headline": "Attendez, ne partez pas !",
  "cancellation_funnel.revert_cancel.stay.card.body": "Restez avec nous et payez seulement {price} pour. Vous recommencerez à payer le prix plein le {date}.",
  "cancellation_funnel.revert_cancel.stay.card.title": "Rester un mois de plus pour {price}",
  "cancellation_funnel.revert_cancel.stay.success.body": "Vous paierez un montant de {discountAmount} lors de votre prochaine échéance le {discountDate}. À compter du {renewalDate}, vous paierez le tarif standard de {renewalAmount} tous les mois.",
  "cancellation_funnel.revert_cancel.success.body": "Téléchargez l’application pour lire et écouter. Vous serez automatiquement connecté(e).",
  "cancellation_funnel.revert_cancel.success.title": "Quel plaisir de savoir que vous restez !",
  "cancellation_funnel.success.body": "Nous espérons vous revoir bientôt !",
  "cancellation_funnel.title": "Pourquoi quittez-vous Nextory ?",
  "catalog.my_account": "Mon compte",
  "catalog.my_booklists": "Mes livres",
  "catalog.pagination": "Page {current} de {last}",
  "catalog.sign_in": "Connexion",
  "catalog.sign_up": "Créer un compte",
  "catalogue.audiobooks.hero.body": "Trouvez vos livres numériques et audio préférés parmi des centaines de milliers d'ouvrages. À partir de {price} par mois. Résiliez votre abonnement quand vous le voulez.",
  "catalogue.audiobooks.hero.title": "Écoutez, l’ère des\nlivres audio est là",
  "catalogue.book.author": "Auteur(e) :",
  "catalogue.book.comment.anonymous": "Anonyme",
  "catalogue.book.cover_warning": "Certaines couvertures ne sont visibles qu'après connexion",
  "catalogue.book.description.hide": "Masquer",
  "catalogue.book.description.show": "Afficher plus",
  "catalogue.book.duration": "Durée :",
  "catalogue.book.format_audiobook": "Livre audio",
  "catalogue.book.format_audiobook_ebook": "Livres numériques et audio",
  "catalogue.book.format_ebook": "Livre numérique",
  "catalogue.book.info.isbn": "ISBN : {code}",
  "catalogue.book.info.language": "Langue : {language}",
  "catalogue.book.info.published": "Publication : {date}",
  "catalogue.book.info.publisher": "Édition : {name}",
  "catalogue.book.narrator": "Narration :",
  "catalogue.book.ratings": "1 évaluation|{n} évaluations",
  "catalogue.book.serie": "Série :",
  "catalogue.book.write_review": "Pour écrire un avis, vous devez télécharger l’application",
  "catalogue.books_categories.hero.description": "Découvrez tout un monde de lectures fascinantes et d’histoires captivantes issues de nombreux genres et adaptées à tous les goûts.",
  "catalogue.ebooks.hero.title": "Un bon vieux\nlivre numérique",
  "catalogue.footer.body": "Jetez un œil à notre sélection.",
  "catalogue.footer.button": "Voir les livres",
  "catalogue.footer.title": "Nos livres vous intriguent ?",
  "catalogue.landing.phone.alt": "Ouvrir « {book} » de/d’ {author}",
  "catalogue.magazines_categories.hero.description": "Découvrez votre nouveau magazine préféré ! Parcourez de nombreux genres susceptibles de vous intéresser.",
  "catalogue.pagination.last_page": "Tous les livres ont été affichés.",
  "catalogue.pagination.last_page_author": "Vous avez affiché tous les livres écrits par : {author}",
  "catalogue.pagination.last_page_category": "Vous avez affiché tous les livres de la catégorie : {category}",
  "catalogue.pagination.last_page_comments": "Fin des commentaires",
  "catalogue.pagination.last_page_narrator": "Vous avez affiché tous les livres lus par : {narrator}",
  "catalogue.pagination.last_page_series": "Vous avez affiché tous les livres de la série : {series}",
  "catalogue.pagination.show_more_books": "Afficher plus",
  "catalogue.pagination.show_more_comments": "Afficher plus",
  "catalogue.search.input_label": "Rechercher",
  "catalogue.search.no_results": "Nous n’avons rien trouvé. Essayez {action} pour trouver autre chose ou consultez nos suggestions.",
  "catalogue.search.no_results.cta": "une nouvelle recherche",
  "catalogue.search.reset": "Réinitialiser",
  "catalogue.search.results": "{n} résultat|{n} résultats",
  "catalogue.search.suggestions": "Vous n'avez pas trouvé ce que vous cherchiez ? Jetez donc un œil aux suggestions ci-dessous.",
  "catalogue.usp1.audiobook.body": "Écoutez vos livres favoris dans les transports ou en faisant du sport et vos tâches ménagères. Avec Nextory, vous pourrez satisfaire vos envies littéraires n’importe où et n’importe quand.",
  "catalogue.usp1.audiobook.title": "Fini les tâches ménagères ennuyeuses",
  "catalogue.usp1.ebook.body": "Fini les gros livres dans les sacs à dos ! Profitez d’histoires infinies à portée de clic, n’importe où et n’importe quand. Lancez-vous dans une aventure littéraire !",
  "catalogue.usp1.ebook.title": "Une bibliothèque dans votre poche",
  "catalogue.usp2.body": "Que vous soyez un lecteur occasionnel ou chevronné, vous trouverez votre bonheur parmi nos abonnements. Nous proposons également une grande sélection de livres pour enfants de tous les âges.",
  "catalogue.usp2.title": "Des livres sur-mesure",
  "catalogue.usp3.body": "Saviez-vous que lire permettait de réduire le stress ? D’après des études, lire 10 min par jour permettrait de se sentir plus détendu.",
  "catalogue.usp3.title": "Calmez votre esprit",
  "change_subscription.indicatior_current_subscription": "Abonnement actuel",
  "download_app.body": "Pour commencer à lire et écouter des livres, vous devez télécharger l'application, disponible sur iOS et Android.",
  "download_app.title": "Télécharger l'application",
  "epage.pick_subscription.silver.usp_max_hours": "• Lisez et écoutez jusqu'à 30 heures par mois",
  "features.ID": "ID",
  "features.available": "Disponible",
  "features.date_of_transaction": "Date de la transaction",
  "features.notAvailable": "Non disponible",
  "features.price": "Prix",
  "features.quantity": "Quantité",
  "features.subscription": "Abonnement",
  "footer.cookies": "Cookies",
  "footer.copyright": "Copyright © 2023 Nextory AB",
  "footer.imprint": "Mentions légales",
  "footer.menu_become_partner": "Rejoignez-nous",
  "footer.menu_becomeareader": "Lisez avec passion",
  "footer.menu_career": "Offres d'emploi",
  "footer.menu_career.url": "https://career.nextory.com/",
  "footer.menu_investor": "Service aux actionnaires",
  "footer.menu_our_story": "Notre histoire",
  "footer.menu_press": "Presse",
  "footer.menu_press.url": "https://news.cision.com/nextory-ab",
  "footer.menu_sell": "Vendre sur Nextory",
  "footer.privacy": "Politique de confidentialité",
  "footer.terms": "Conditions d'utilisation",
  "footer.title_about_us": "À propos",
  "footer.title_start_reading": "Commencer à lire",
  "fr.footer.author_login": "Espace éditeur",
  "fr.footer.content_partner": "Devenez éditeur partenaire",
  "fr.footer.content_partner.url": "https://docs.google.com/forms/d/e/1FAIpQLScHG7Ttx2EnAadUxkv52isyDI52p4yoTq3mQNOEs4E3dGjNAQ/viewform",
  "fr.footer.pro_offers": "Offres Pro",
  "fr.footer.pro_offers.url": "https://nextory.com/fr/collab/partner/nx-pass/",
  "fr.footer.publisher_login": "Espace diffuseur",
  "fr.footer.sfr": "Nextory One",
  "fr.footer.title": "Professionnels",
  "fr.offer.global_usp_1_screen": "1 écran",
  "fr.offer.global_usp_2_screen": "2 écrans",
  "fr.offer.global_usp_4_screen": "4 écrans",
  "fr.offer.global_usp_all_in_one_app": "Toutes vos lectures en une seule application",
  "giftcard.amount1.microcopy": "Assez pour 1 mois de Premium",
  "giftcard.amount2.microcopy": "Assez pour 3 mois de Premium",
  "giftcard.amount3.microcopy": "Assez pour 6 mois de Premium",
  "giftcard.amount4.microcopy": "Assez pour 12 mois de Premium",
  "giftcard.amount_2": "507 €",
  "giftcard.amount_3": "860 €",
  "giftcard.amount_4": "1 720 €",
  "giftcard.body": "Votre destinataire peut choisir son abonnement au moment d’utiliser la carte cadeau sur notre site.",
  "giftcard.discount.microcopy": "15 % de réduction",
  "giftcard.email_giftcard.body": "Saisissez les informations de votre destinataire.",
  "giftcard.multi_purchase": "Vous souhaitez acheter plus de 10 cartes cadeaux ? Contactez-nous à l’adresse partner@nextory.com",
  "giftcard.payment.body": "Vous pourrez saisir les informations de votre destinataire à l’étape suivante. Vous pouvez soit imprimer votre carte cadeau, soit l’envoyer par e-mail.",
  "giftcard.payment.button": "Effectuer le paiement",
  "giftcard.payment.email.label": "Votre adresse e-mail",
  "giftcard.sent.success.button": "Acheter à nouveau",
  "giftcard.sent.success.title": "La carte cadeau a été envoyée !",
  "giftcard.subheader": "Choisir un montant",
  "giftcard.success.body": "Merci pour votre achat ! Vous recevrez une confirmation par e-mail.",
  "giftcard.success.button.email": "Envoyer par e-mail",
  "giftcard.success.button.print": "Imprimer",
  "giftcard.success.subheader": "Comment souhaitez-vous offrir votre carte cadeau ?",
  "giftcard.success.title": "Voilà !",
  "giftcard.title": "Pourquoi offrir un livre quand on peut en offrir 350 000 ?",
  "global.accessibility.nav.breadcrumb": "Fil d'Ariane",
  "global.accessibility.nav.footer": "Navigation de pied de page",
  "global.accessibility.nav.footer.legals": "Liens légaux Nextory",
  "global.accessibility.nav.footer.networks": "Réseaux sociaux Nextory",
  "global.accessibility.nav.footer.pro": "Navigation de pied de page pour professionnels",
  "global.accessibility.nav.main": "Menu principal",
  "global.action.back_to_my_pages": "← Retour à Mes pages",
  "global.action.maybe_later": "Peut-être plus tard",
  "global.action_go_back": "Retour",
  "global.android.download": "Disponible sur",
  "global.button.save": "Sauvegarder",
  "global.button_add_profile": "Ajouter un profil",
  "global.button_back": "Retour",
  "global.button_cancel": "Annuler",
  "global.button_close": "Fermer",
  "global.button_continue": "Continuer",
  "global.button_create_profile": "Créer un profil",
  "global.button_delete": "Supprimer",
  "global.button_external_login": "Connexion partenaire",
  "global.button_finish": "Terminer",
  "global.button_login": "Se connecter",
  "global.button_login_apple": "Se connecter via Apple",
  "global.button_login_fb": "Se connecter via Facebook",
  "global.button_login_google": "Se connecter via Google",
  "global.button_login_partner": "Connexion avec {partner}",
  "global.button_logout": "Se déconnecter",
  "global.button_send": "Envoyer",
  "global.button_skip": "Passer",
  "global.button_try": "Essayer {name}",
  "global.button_try_family": "Essayez l'abonnement Famille",
  "global.button_try_free": "{trialDays} jours gratuits",
  "global.button_try_gold": "Essayez l'abonnement Premium",
  "global.button_try_silver": "Essayez l'abonnement Standard",
  "global.error.title": "Une erreur s'est produite",
  "global.error.wrong_email": "Cette adresse e-mail ne fonctionne pas. Réessayez.",
  "global.error.wrong_password": "Vérifiez que vous avez bien saisi le bon mot de passe.",
  "global.error_incorrect_password_length": "Le mot de passe doit contenir entre 6 et 60 caractères.",
  "global.freetrial": "{trialDays} jours gratuits.",
  "global.ios.download": "Télécharger dans",
  "global.label.bbl": "Numéro de carte de membre (9 chiffres)",
  "global.label.code": "Code",
  "global.label.klm": "Numéro Flying Blue",
  "global.label.membership_number": "Numéro de membre",
  "global.label.norwegian": "Numéro de Reward",
  "global.label_child_profile": "Profil enfant",
  "global.link_faq": "Aide et contact",
  "global.link_faq.url": "https://support.youboox.fr/hc/fr",
  "global.microcopy.per_month": "/mois",
  "global.microcopy_cancel_anytime": "Sans engagement",
  "global.microcopy_pp": "Politique de confidentialité",
  "global.microcopy_profiles": "Profils",
  "global.microcopy_tc": "Conditions d'utilisation",
  "global.microcopy_tc_pp": "En continuant, vous acceptez les {term} et notre {privacy}",
  "global.number_1": "1",
  "global.number_2": "2",
  "global.number_3": "3",
  "global.number_4": "4",
  "global.payment_method_card": "Carte",
  "global.payment_method_dimoco": "DIMOCO",
  "global.payment_method_giftcard": "Carte cadeau",
  "global.payment_method_ideal": "iDEAL",
  "global.payment_method_other": "Autres moyens de paiement",
  "global.period_unit.day": "jour|jours",
  "global.period_unit.month": "mois|mois",
  "global.period_unit.week": "semaine|semaines",
  "global.period_unit.year": "an|ans",
  "global.price": "9,99 €",
  "global.profile": "Profil",
  "global.social_network.facebook.url": "https://www.facebook.com/nextory.fr",
  "global.social_network.instagram.url": "https://www.instagram.com/nextory.fr/",
  "global.subscription_usp.1_simultanoeus_profile": "Un seul écran peut utiliser l'application à la fois",
  "global.subscription_usp.family_many_profiles": "4 écrans peuvent utiliser l'application en même temps",
  "global.subscription_usp.unlimited": "Lisez et écoutez autant que vous le voulez",
  "global.unit.points": "points",
  "hero.logged_in_user.user_name": "David",
  "homepage.banner.movistar.button": "",
  "homepage.banner.movistar.logo_alt": "",
  "homepage.banner.movistar.title": "",
  "homepage.banner.stories_of_iran.read_more": "En savoir plus",
  "homepage.banner.stories_of_iran.text": "{readmore} à propos de Narges Mohammadi, auteur du livre {book} et lauréate du Prix Nobel de la Paix.",
  "homepage.banner.stories_of_iran.white_torture": "\"White Torture\"",
  "homepage.hero.body": "Accédez à des milliers de livres audio, ebooks, BD, journaux, magazines et séries audio exclusives en un clic. À partir de {price} / mois.",
  "homepage.hero.body.magazine_market": "Accédez à des milliers de livres audio, ebooks, BD, journaux, magazines et séries audio exclusives en un clic. À partir de {price} / mois.",
  "homepage.hero.body.omni": "",
  "homepage.hero.img_desktop": "nx-images/home/hero/global/hero-offer-desktop.jpg",
  "homepage.hero.img_mobile": "nx-images/home/hero/global/hero-offer-mobile.jpeg",
  "homepage.hero.img_tablet": "nx-images/home/hero/global/hero-offer-tablet.jpeg",
  "homepage.hero.title": "Une bonne histoire peut changer votre journée",
  "homepage.hero.title.omni": "",
  "homepage.menu.language_picker": "Langue",
  "homepage.menu.language_picker.en": "EN",
  "homepage.menu.language_picker.fr": "FR",
  "homepage.menu_books": "Livres",
  "homepage.menu_buy_giftcard": "Acheter une carte cadeau",
  "homepage.menu_campaign_code": "Utiliser un code promotionnel",
  "homepage.menu_categories": "Catalogue",
  "homepage.menu_download_app": "Télécharger l'application",
  "homepage.menu_help": "Aide",
  "homepage.menu_home": "Accueil",
  "homepage.menu_magazines": "Magazines",
  "homepage.menu_redeem_giftcard": "Utiliser une carte cadeau",
  "homepage.more_about.body.usp_books_for_family": "Avec Nextory, découvrez des histoires pour tous les âges et tous les goûts. Romans, thrillers, développement personnel, jeunesse, cuisine... il y a forcément un livre pour vous sur Nextory !",
  "homepage.more_about.body.usp_life_better_w_books": "Prendre du temps pour vous ? Apprendre quelque chose de nouveau ? Sortir de votre zone de confort ? Avec Nextory, ouvrez vos horizons et nourrissez votre imagination de milliers d’histoires.",
  "homepage.more_about.body.usp_read_or_listen": "Écoutez et lisez des histoires quand vous voulez, où vous voulez et sur tous vos écrans ! Nextory est la seule application proposant tous les formats de lecture, disponible sur iOS, Android et le web.",
  "homepage.more_about.body.usp_read_or_listen.magazine_market": "Lisez ou écoutez des livres audio, des e-books et des magazines sur votre téléphone ou tablette à tout moment, n'importe où. L'application est disponible pour iOS et Android.",
  "homepage.more_about.img_1.alt": "Image de tablette",
  "homepage.more_about.img_1.src": "nx-images/home/more-about-nx/fr/1-FR.jpg",
  "homepage.more_about.img_2.alt": "Images de livres",
  "homepage.more_about.img_2.src": "nx-images/home/more-about-nx/fr/2-FR.jpg",
  "homepage.more_about.img_3.alt": "Une femme écoute des livres audio",
  "homepage.more_about.img_3.src": "nx-images/home/more-about-nx/fr/3-FR.jpg",
  "homepage.more_about.title": "Notre application",
  "homepage.more_about.title.usp_books_for_family": "Des histoires pour tous",
  "homepage.more_about.title.usp_life_better_w_books": "Inspirez-vous !",
  "homepage.more_about.title.usp_read_or_listen": "Écouter ou lire ? C’est vous qui décidez",
  "homepage.phone.img_phone_cover.alt": "Illustrations de livres",
  "homepage.phone.img_phone_cover.src": "nx-images/home/phone/fr/phonebookcovers_FR.jpg",
  "homepage.pick_subscription.body_top_of_page": "Tous les abonnements proposent {trialDays} jours d'essai gratuit. Sans engagement, annulez à tout moment.",
  "homepage.pick_subscription.days_trial": "{days} jours offerts",
  "homepage.pick_subscription.family_microcopy": " ",
  "homepage.pick_subscription.family_title": "Abonnement Famille",
  "homepage.pick_subscription.features.FAMILY": "{multi_screen}\n{time_credit}\nLivres et BD en illimité\nMagazines et quotidiens en illimité\nSéries audio exclusives et podcasts en illimité\nLecture hors connexion",
  "homepage.pick_subscription.features.GOLD": "{multi_screen}\n{time_credit}\nLivres et BD en illimité\nMagazines et quotidiens en illimité\nSéries audio exclusives et podcasts en illimité\nLecture hors connexion",
  "homepage.pick_subscription.features.SILVER": "{multi_screen}\n{time_credit}\nLivres et BD en illimité\nMagazines et quotidiens en illimité\nSéries audio exclusives et podcasts en illimité\nLecture hors connexion",
  "homepage.pick_subscription.features.audio_series": "Séries audio exclusives et podcasts en illimité",
  "homepage.pick_subscription.features.ebook": "Livres et BD en illimité",
  "homepage.pick_subscription.features.hours.limited": "Lisez et écoutez jusqu'à {n} heures par mois",
  "homepage.pick_subscription.features.hours.unlimited": "Lisez et écoutez autant que vous le souhaitez",
  "homepage.pick_subscription.features.max_profiles_login": "{n} profil peut utiliser l'application en même temps|{n} profils peuvent utiliser l'application en même temps",
  "homepage.pick_subscription.features.offline": "Lecture hors connexion",
  "homepage.pick_subscription.features.press_magazine": "Magazines et quotidiens en illimité",
  "homepage.pick_subscription.features.profiles": "{n} profil|{n} profils",
  "homepage.pick_subscription.gold_microcopy": " ",
  "homepage.pick_subscription.gold_title": "Or ",
  "homepage.pick_subscription.micro_copy.BASIC": "Le plus rentable",
  "homepage.pick_subscription.micro_copy.FAMILY": "Lisez ensemble",
  "homepage.pick_subscription.micro_copy.GOLD": "Le plus populaire",
  "homepage.pick_subscription.micro_copy.PREMIUM": "Le plus populaire",
  "homepage.pick_subscription.micro_copy.SILVER": "Le plus rentable",
  "homepage.pick_subscription.micro_copy.UNLIMITED": "Lisez ensemble",
  "homepage.pick_subscription.price": "Puis {price} par mois",
  "homepage.pick_subscription.price.unlimited": "Puis à partir de {price} par mois",
  "homepage.pick_subscription.silver_microcopy": " ",
  "homepage.pick_subscription.silver_title": "Standard",
  "homepage.pick_subscription.title_top_of_page": "Choisissez votre abonnement",
  "homepage.pick_subscription.unlimited.button": "Voir les options",
  "homepage.popular_books.body": "Nous avons des ouvrages pour petits et grands. Des romans policiers aux livres réconfortants, en passant par des histoires à écouter avant de dormir, jetez un œil à nos listes pour trouver votre bonheur.",
  "homepage.popular_books.title": "Livres populaires",
  "homepage.popular_books.title_most_read_books_list": "Livres les plus lus",
  "homepage.popular_books.title_popular_books_list": "Les livres les plus populaires de la semaine",
  "homepage.testimonial.microcopy": "A lu 32 livres entre mai et décembre",
  "homepage.testimonial.name": "Eloïse",
  "homepage.testimonial.quote": "« J'adore cette application, c'est un vrai coup de coeur. Je lis beaucoup et là je peux accéder à des milliers d'ouvrages sans aucune restriction et sur tous les sujets. »",
  "homepage.usp1.body": "Plus de 41 000 personnes ont noté Nextory 5 étoiles sur l'App Store et Google Play.",
  "homepage.usp1.title": "Des milliers d’histoires vous attendent",
  "homepage.usp2.body": "Grâce à Nextory, accédez à la lecture sous toutes ses formes en un clic.",
  "homepage.usp2.title": "Votre appli de lecture tout-en-un",
  "homepage.usp3.body": "Écoutez des histoires dans les transports, pendant votre séance de sport, en famille ou en solo pour un moment de détente.",
  "homepage.usp3.title": "Découvrez le livre audio",
  "login.action_forgot_password": "Mot de passe oublié ?",
  "login.action_start_trial.link": "essai gratuit.",
  "login.action_start_trial.text": "Vous n'avez pas encore de compte ? Commencez votre",
  "login.desktop.quote.title": "Les livres nous font vivre toutes les vies que nous n'avons pas le temps de vivre.",
  "login.desktop.quote_unknown": "Auteur inconnu",
  "login.error_no_email": "Saisissez votre adresse e-mail.",
  "login.error_no_password": "Saisissez votre mot de passe.",
  "login.external_account.partner_free": "Free ou Free mobile",
  "login.external_account.partner_orange_tunisie": "Orange Tunisie",
  "login.external_account.partner_orange_tunisie.button_verify": "Vérifier mon numéro",
  "login.external_account.partner_orange_tunisie.label_mobile": "Numéro de téléphone",
  "login.external_account.partner_orange_tunisie.pin.buttonLabel": "Connexion à Nextory",
  "login.external_account.partner_orange_tunisie.pin.label": "Code de confirmation",
  "login.external_account.partner_orange_tunisie.pin.legend": "Entrez votre Pin de confirmation reçu par SMS.",
  "login.external_account.partner_orange_tunisie.pin.placeholder": "Entrez code de confirmation reçu par SMS.",
  "login.external_account.partner_orange_tunisie.pin_send.message": "Un code pin de confirmation vous a été envoyé par SMS.",
  "login.external_account.partner_orange_tunisie.placeholder": "Merci de renseigner votre numéro de téléphone Orange",
  "login.external_account.partner_orange_tunisie.title": "Merci de renseigner votre numéro au format XX XXX XXX ou XXXXXXXX",
  "login.external_account.partner_orange_tunisie_microcopy": "Saisissez votre numéro de téléphone au format 53 123 456.",
  "login.external_account.partner_orange_tunisie_prefix": "216",
  "login.external_account.partner_sfr": "SFR ou RED by SFR",
  "login.external_account.partner_videofutur": "VIDEOFUTUR",
  "login.external_account.title": "Connexion avec un compte partenaire",
  "login.forgot_password.body": "Saisissez votre adresse e-mail et nous vous enverrons un lien pour que vous puissiez réinitialiser votre mot de passe.",
  "login.forgot_password.error_email_doesnt_exist": "Nous ne reconnaissons pas cette adresse e-mail. Assurez-vous d'utiliser l'adresse qui vous a servi à créer un compte.",
  "login.forgot_password.success_screen.title": "Nous allons vous aider ! Pensez à consulter votre boîte de réception.",
  "login.forgot_password.title": "Mot de passe oublié ?",
  "login.input_label_password": "Mot de passe",
  "login.required_field.microcopy": "* Champs requis",
  "meta.author.description": "Livres numériques et audio de {authorName} 📖 Lire ou écouter ? C'est vous qui décidez ! ✓ Gratuit pendant {trialDays} jours",
  "meta.author.title": "{authorName} - Tous les livres numériques et audio",
  "meta.book.description": "Lisez gratuitement « {bookTitle} » 📖 de {authorName} ✓ Disponible en {bookFormat} ✓ Gratuit pendant {trialDays} jours",
  "meta.book.title": "{bookTitle} | {bookFormat} | {authorName} | Nextory",
  "meta.category.description": "Découvrez gratuitement la catégorie {categoryName}. Essayez Nextory gratuitement pendant {trialDays} jours, sans engagement. Plus de 350 000 livres ✓ Lisez et écoutez autant que vous le voulez ✓",
  "meta.category.title": "{categoryName} – Tous les livres | Gratuit pendant {trialDays} jours | Voir nos tops",
  "meta.default.description": "Lire ou écouter ? C'est vous qui décidez ! ➤ Trouvez vos livres préférés et ceux de vos enfants parmi notre catalogue de livres numériques et audio ! 📖",
  "meta.default.title": "Nextory: Livres audio et E-books",
  "meta.home.description": "Lire ou écouter ? C'est vous qui décidez ! ➤ Trouvez vos livres préférés et ceux de vos enfants parmi notre catalogue de livres numériques et audio ! 📖 Gratuit pendant {trialDays} jours",
  "meta.home.title": "Nextory : livres numériques et audio, essai gratuit de {trialDays} jours",
  "meta.login.description": "Connectez-vous à Nextory ➤ Trouvez vos livres préférés et ceux de vos enfants parmi des centaines de milliers de livres numériques et audio",
  "meta.login.title": "Nextory : connexion",
  "meta.series.description": "Lisez gratuitement {serieName} 📖 Gratuit pendant {trialDays} jours",
  "meta.series.title": "{serieName} | Nextory",
  "migration.overtake.cta.download_app": "Téléchargez la nouvelle application",
  "migration.overtake.cta.learn_more": "Plus d'informations",
  "migration.overtake.post_launch.content": "Pour continuer à lire sur Nextory, il vous suffit de télécharger la nouvelle application. Connectez-vous avec vos identifiants habituels pour retrouver toutes vos lectures.",
  "migration.overtake.post_launch.title": "Téléchargez la nouvelle application",
  "migration.overtake.pre_launch.content": "Nous avons travaillé dur pour vous proposer la meilleure expérience de lecture possible. La nouvelle application sera disponible le 10 janvier.",
  "migration.overtake.pre_launch.title": "L'application évolue",
  "migration.underway.faq.answer_eight": "Tous les coupons valides pourront encore être activés sur le site internet.",
  "migration.underway.faq.answer_eleven": "La progression de vos lectures et vos marque-pages sont transférés dans la nouvelle application afin que vous puissiez reprendre votre lecture là où vous l'avez laissée.",
  "migration.underway.faq.answer_five": "L’application internet ne sera plus disponible. Vous devrez télécharger l'application sur Google Play ou sur l’App Store pour profiter d’une meilleure expérience.",
  "migration.underway.faq.answer_nine": "Dans la nouvelle application, vous pouvez créer des profils pour enfant. Vous pourrez également mettre en place un code PIN pour passer du profil enfant à un profil normal. Seuls les livres adaptés aux enfants de moins de 12 ans sont visibles sur ce type de profil.",
  "migration.underway.faq.answer_seven": "Vous pouvez trouver toutes vos listes dans l’onglet « Ma bibliothèque ».",
  "migration.underway.faq.answer_six": "Les conditions de votre abonnement restent inchangées à l'exception des droits associés aux profils qui remplacent le nombre d'écrans : avec l'abonnement Standard, la lecture simultanée sur plusieurs profils n'est pas possible. Avec l'abonnement Premium, 2 profils peuvent utiliser l'application simultanément. Avec l'abonnement Famille, 4 profils peuvent utiliser l'application simultanément.",
  "migration.underway.faq.answer_ten": "La nouvelle version de l’application vous propose trois nouvelles fonctionnalités majeures : \n- Le partage de compte avec jusqu’à 3 autres personnes : chaque profil obtient des recommandations qui lui sont adaptées et peut créer ses propres listes.\n- Une plus grande sélection avec de meilleures options de filtrage afin de vous permettre de trouver plus rapidement ce que vous voulez.\n- Des statistiques vous permettant de suivre vos lectures au cours du temps.",
  "migration.underway.faq.answer_twelve": "Vous devrez les télécharger à nouveau dans la nouvelle application pour pouvoir en profiter sans connexion internet.",
  "migration.underway.faq.anwer_four": "Tous les appareils iOS dotés de la version 14 ou d’une version plus récente et tous les appareils Android dotés de la version 5 ou d’une version plus récente.",
  "migration.underway.faq.anwer_one": "Vos livres et magazines sont disponibles dans la nouvelle application et vos marque-pages sont également synchronisés. Vous pourrez continuer à lire et à écouter là où vous vous êtes arrêté.",
  "migration.underway.faq.anwer_three": "Au cours de cette dernière année, nous vous avons mijoté quelque chose de meilleur et de bien plus adapté aux utilisateurs. Nous voulions vous proposer une meilleure expérience avec de nouvelles fonctionnalités : plus de titres, plus de langues, 4 profils, un profil spécial réservé aux enfants, un aperçu des titres à venir sur l'application, des défis de lecture et des statistiques !",
  "migration.underway.faq.anwer_two": "À partir du 10 janvier 2024, vous ne pourrez plus utiliser l’application Nextory. Cliquez sur « Télécharger la nouvelle application » pour profiter d’une toute nouvelle expérience et retrouver vos livres et magazines.",
  "migration.underway.faq.title_eight": "Puis-je activer un coupon que j'ai reçu ?",
  "migration.underway.faq.title_eleven": "Pourrais-je continuer à lire mes livres là où je me suis arrêté ?",
  "migration.underway.faq.title_five": "Puis-je continuer à lire sur internet ?",
  "migration.underway.faq.title_four": "Quels appareils l’application prend-elle en charge ?",
  "migration.underway.faq.title_nine": "La nouvelle application est-elle dotée d’un contrôle parental ?",
  "migration.underway.faq.title_one": "Qu’adviendra-t-il de mes livres et magazines ?",
  "migration.underway.faq.title_seven": "Qu’adviendra-t-il de mes listes ?",
  "migration.underway.faq.title_six": "Mon abonnement sera-t-il modifié ?",
  "migration.underway.faq.title_ten": "Quelles sont les nouveautés de l’application ?",
  "migration.underway.faq.title_three": "Pourquoi sortez-vous une nouvelle application ?",
  "migration.underway.faq.title_twelve": "Qu’adviendra-t-il du contenu que j'ai téléchargé ?",
  "migration.underway.faq.title_two": "Puis-je continuer à utiliser l'application Nextory ?",
  "migration.underway.post_launch.header.content": "Des profils supplémentaires, un mode enfants, plus de contenu, des statistiques de lecture... L'application évolue ! Téléchargez-la pour profiter de toutes ces nouvelles fonctionnalités.",
  "migration.underway.post_launch.header.sub_content": "L'application est disponible pour iOS et Android",
  "migration.underway.post_launch.header.title": "L'application évolue !",
  "migration.underway.pre_launch.header.content": "Nous avons travaillé dur pour vous proposer la meilleure expérience de lecture possible. La nouvelle application sera disponible le 10 janvier 2024.",
  "migration.underway.pre_launch.header.title": "L'application évolue !",
  "migration.underway.slider.action.next_slide": "Page suivante",
  "migration.underway.slider.action.previous_slide": "Page précédente",
  "migration.underway.slider.button_title_one": "Un catalogue enrichi",
  "migration.underway.slider.content_one": "Vous aurez accès à plus de contenus dans différentes langues. Nous avons également amélioré la recherche, pour vous permettre de trouver facilement le titre que vous cherchez.",
  "migration.underway.slider.content_three": "Découvrez comment évoluent vos habitudes de lecture, mais aussi vos catégories préférées, votre temps de lecture, et bien plus.",
  "migration.underway.slider.content_two": "Vous pouvez créer jusqu'à 3 profils différents pour vous et votre famille. Vous pouvez également créer un profil enfant avec des contenus dédiés.",
  "migration.underway.slider.image_alt_one": "Une image montrant la bibliothèque sur l'app. On retrouve tout d'abord les lectures récentes et en-dessous, les listes de lecture avec des livres et des magazines.",
  "migration.underway.slider.image_alt_three": "Une image avec un graphique représentant les statistiques de lecture du mois en cours",
  "migration.underway.slider.image_alt_two": "Une image montrant différents profils",
  "migration.underway.slider.title_one": "Un catalogue enrichi",
  "migration.underway.slider.title_three": "Statistiques de lecture",
  "migration.underway.slider.title_two": "Créez 3 nouveaux profils",
  "migration.underway.slider_header.content": "Des profils supplémentaires, un catalogue enrichi, des statistiques de lecture, et bien d'autres nouveautés.",
  "migration.underway.slider_header.title": "Quelles sont les principales évolutions ?",
  "migration.underway.three_questions.answer_one": "Tout ce que vous aurez à faire c’est cliquer sur « télécharger l’application » et vous serez directement redirigé sur la page de téléchargement. Une fois l'application téléchargée, vous serez automatiquement connecté avec vos anciens identifiants. Vous trouverez vos livres et magazines dans l’onglet « Ma bibliothèque ».",
  "migration.underway.three_questions.answer_three": "Le prix reste le même. Vous continuerez de payer le même prix qu’aujourd'hui.",
  "migration.underway.three_questions.answer_two": "Vos e-mail et mot de passe restent les mêmes.",
  "migration.underway.three_questions.title_one": "Dois-je faire quelque chose ?",
  "migration.underway.three_questions.title_three": "Le prix change-t-il ?",
  "migration.underway.three_questions.title_two": "Mes identifiants de connexion seront-ils modifiés ?",
  "mypage.profile.edit": "Modifier le profil",
  "mypages.account.action_change_email": "Modifier l'adresse e-mail",
  "mypages.account.action_change_password": "Modifier le mot de passe",
  "mypages.account.market": "Votre marché: {name}",
  "mypages.account.password_preview": "Mot de passe : ••••••••••••",
  "mypages.account.title": "Compte",
  "mypages.action_download_app": "Télécharger l'application",
  "mypages.banner_add_more_profiles": "Psst. Vous pouvez encore ajouter trois profils supplémentaires !",
  "mypages.banner_update_payment": "Mettez à jour vos informations de paiement",
  "mypages.change_email.error_email_used_previous": "Utilisé une adresse e-mail différente de l'actuelle.",
  "mypages.change_email.error_emails_dont_match": "Les adresses e-mail ne correspondent pas.",
  "mypages.change_password.error_password_similar_previous": "Le mot de passe est le même que l'ancien.",
  "mypages.change_password.error_passwords_dont_match": "Les mots de passe ne correspondent pas.",
  "mypages.change_password.snackbar_email_updated": "Cette adresse e-mail a été modifiée.",
  "mypages.change_password.snackbar_password_updated": "Le mot de passe a été modifié.",
  "mypages.change_sub.pending.banner": "Vous avez changé votre abonnement pour {name}. Votre nouvel abonnement sera activé lors de la date de votre prochain paiement, le {date}.",
  "mypages.collect_points.body": "Collectez des points mensuels avec l'un de nos partenaires.",
  "mypages.collect_points.connected.body": "Vous obtenez des points avec [partner]. Pour changer de partenaire, vous devez d'abord mettre fin à votre collaboration avec [partner].",
  "mypages.collect_points.connected.indicator": "Obtenir des {unit}",
  "mypages.collect_points.cta.connect": "Obtenir des points",
  "mypages.collect_points.disconnect.dialogue.body": "Voulez-vous arrêter d'obtenir des {unit} avec {partner} ?",
  "mypages.collect_points.disconnect.dialogue.cant_reconnect.body": "Voulez-vous arrêter d'obtenir des points avec [partner] ? Vous ne pourrez plus obtenir de points avec ce partenaire à l'avenir.",
  "mypages.collect_points.disconnect.dialogue.title": "Arrêter d'obtenir des {unit} ?",
  "mypages.collect_points.partner_callback.norwegian": "",
  "mypages.collect_points.title": "Points",
  "mypages.collect_points.unit.bbl": "",
  "mypages.collect_points.unit.coop": "",
  "mypages.collect_points.unit.klm": "Miles",
  "mypages.collect_points.unit.norwegian": "CashPoints",
  "mypages.collect_points.unit.sj": "",
  "mypages.information.label3": "Adresse",
  "mypages.information.label4": "Code postal",
  "mypages.information.label6": "Ville",
  "mypages.information.title": "Informations",
  "mypages.limited_free_trial.activated.success.back.button": "Retour à Mon compte",
  "mypages.limited_free_trial.activated.success.body": "Votre abonnement a été activé et la somme de {price} vous a été prélevée. Ouvrez l'application pour reprendre votre lecture.",
  "mypages.limited_free_trial.activated.success.open_app.button": "Ouvrir l'application",
  "mypages.limited_free_trial.activated.success.title": "Super ! Vous pouvez reprendre votre lecture et votre écoute.",
  "mypages.limited_free_trial_module.body": "Pour reprendre votre lecture ou votre écoute, vous pouvez vous abonner dès maintenant ou attendre {period} {periodUnit} pour que l'abonnement s'active automatiquement.",
  "mypages.limited_free_trial_module.button": "Activer l'abonnement",
  "mypages.limited_free_trial_module.title": "Votre limite de {hours} heure(s) est écoulée",
  "mypages.listening_time.active": "Durée d’écoute (livres audio) : {hours} h {minutes} min",
  "mypages.listening_time.renewal": "Durée d’écoute (livres audio) : 0 h 0 min, en attente de renouvellement",
  "mypages.logged_in_user.user_email": "david.bredenberg@nextory.com",
  "mypages.max_profiles_amount": "Le nombre maximum de profils pour ce compte est {n}. Pour ajouter un autre profil, vous devez en supprimer un.",
  "mypages.parental_control.body": "Faites en sorte que les plus jeunes puissent lire en toute sécurité en bloquant l'accès au contenu adulte.",
  "mypages.parental_control.title": "Contrôle parental",
  "mypages.parental_control.toggle": "Bloquer le contenu adulte",
  "mypages.payment.delete_payment.error.snackbar": "Nous n'avons pas pu supprimer vos informations de paiement.",
  "mypages.payment.delete_payment.snackbar": "Vos informations de paiement ont été supprimées.",
  "mypages.payment.info_free_trial_end": "Votre période d'essai gratuite prend fin le [date].\nAprès votre période d'essai, vous paierez [amount] par mois.",
  "mypages.payment.info_gift_card_used_up": "Votre carte cadeau expirera le [date].",
  "mypages.payment.info_subscription_cancelled_date": "Vous avez annulé votre abonnement le{date}.",
  "mypages.payment.info_subscription_cancelled_date_days_left": "Vous avez résilié votre abonnement le {cancelDate} ; vous pouvez néanmoins continuer à lire et à écouter jusqu'au {subscriptionStopDate}.",
  "mypages.payment.payment_history.action_receipt": "Reçu",
  "mypages.payment.payment_history.transaction_amount": "Coût:",
  "mypages.payment.payment_history.transaction_date": "05/24/2020",
  "mypages.payment.update_payment.body": "Vos nouvelles informations seront utilisées lors du prochain paiement, le {date}.",
  "mypages.payment.update_payment.error": "Votre paiement n’a pas été effectué. Vérifiez vos informations de paiement.",
  "mypages.payment.update_payment.snackbar": "Vos informations de paiement ont été sauvegardées.",
  "mypages.payment_history.receipt_screen.button_download_receipt": "Télécharger le reçu",
  "mypages.payment_history.receipt_screen.title": "Reçu",
  "mypages.pp.title": "Politique de confidentialité",
  "mypages.profiles.creation_success": "Votre profil {name} a été créé avec succès.",
  "mypages.profiles.delete_profile": "Supprimer le profil",
  "mypages.profiles.delete_profile_confirmation": "Êtes-vous sûr de vouloir supprimer le profil {name} ?",
  "mypages.profiles.deletion_success": "Le profil {name} a été supprimé.",
  "mypages.profiles.edit_profile": "Modifier un profil",
  "mypages.profiles.edition_success": "Votre profil {name} a été modifié avec succès.",
  "mypages.profiles.error_creation": "Un problème est survenu pendant la création de votre profil. Veuillez réessayer.",
  "mypages.profiles.error_edition": "Oups, une erreur s'est produite lors de la modification de votre profil.",
  "mypages.profiles.label_child_profile": "Profil enfant",
  "mypages.profiles.label_name": "Nom",
  "mypages.subscription.change.button": "Changer vers {name}",
  "mypages.subscription.change.success.body": "Vous paierez le nouveau prix dès votre prochain paiement le {date}. Profitez de votre nouvel abonnement !",
  "mypages.subscription.change.success.cta": "Retour à Mes pages",
  "mypages.subscription.change.success.title": "Bravo ! Vous êtes passé(e) à un abonnement {name}.",
  "mypages.subscription.downgrade.success.body": "Votre abonnement sera modifié à votre prochaine échéance de paiement, le {date}.",
  "mypages.subscription.downgrade.success.title": "Vous êtes passé(e) à un abonnement {name}",
  "mypages.subscription.hours_consumed.limited_free_trial.info": "Temps de lecture et d'écoute pendant votre essai gratuit",
  "mypages.subscription.info_account_created_through_partner": "Votre abonnement est géré par {partner}. Contactez ses services si vous voulez changer votre abonnement.",
  "mypages.subscription.limited_free_trial.label": "Essai gratuit",
  "mypages.subscription.time_limited.trial_period.info.body": "Votre abonnement vous permet de lire et d'écouter pendant {n} heures chaque mois. Mais durant votre période d'essai gratuit, vous pouvez lire et écouter autant que vous le voulez. Faites-vous plaisir !",
  "mypages.subscription_payment.action_change_payment": "Changer de moyen de paiement",
  "mypages.subscription_payment.action_change_subscription": "Changer d'abonnement",
  "mypages.subscription_payment.action_delete_payment": "Retirer cette carte",
  "mypages.subscription_payment.action_view_payments": "Voir l'historique de paiement",
  "mypages.subscription_payment.body1": "{number} écran peuvent utiliser l'application en même temps.|{number} écrans peuvent utiliser l'application en même temps.",
  "mypages.subscription_payment.body2": "Prochain paiement: {amount} le {date}",
  "mypages.subscription_payment.button.activate_subscription": "Activer mon abonnement",
  "mypages.subscription_payment.button.button_subscribe": "S'abonner",
  "mypages.subscription_payment.button.reactivate_subscription": "Réactiver l'abonnement",
  "mypages.subscription_payment.button_cancel_subscription": "Annuler mon abonnement",
  "mypages.subscription_payment.card_nr": "**** **** **** 3332",
  "mypages.subscription_payment.free_trial": "Votre offre d'essai prend fin le {date}",
  "mypages.subscription_payment.icon_active": "Actif",
  "mypages.subscription_payment.icon_cancelled": "Annulé",
  "mypages.subscription_payment.subtitle1": "Abonnement Famille, 4 écrans",
  "mypages.subscription_payment.subtitle2": "Moyen de paiement:",
  "mypages.subscription_payment.subtitle3": "Historique de paiement",
  "mypages.subscription_payment.time_remaining": "{hours} heures {minutes} minutes",
  "mypages.subscription_payment.time_remaining.body": "Vos {hours} heures se rechargeront dans {days} jours et seront ensuite réparties entre tous les écrans.",
  "mypages.subscription_payment.time_remaining.title": "Temps restant",
  "mypages.subscription_payment.title": "Abonnement et paiement",
  "mypages.tc.title": "Conditions d'utilisation",
  "mypages.time_consumption.action_disclose_faq": "Comment obtenons-nous ces résultats ?",
  "mypages.time_consumption.action_hide_activity": "Masquer l'activité",
  "mypages.time_consumption.faq.body1": "Écouter un livre audio pendant une heure retire une heure de la période, que vous accédiez à Nextory en ligne ou en mode hors-ligne. Écouter le livre plus rapidement ou plus lentement n'a pas d'incidence sur ce calcul, tout comme le fait de rembobiner ou de sauter de section en section au sein d'un livre.",
  "mypages.time_consumption.faq.body2": "Pour les livres numériques, nous calculons une heure par rapport à un certain nombre de caractères. Cela signifie que vous pouvez lire à la vitesse qui vous convient. Le fait de feuilleter l'ouvrage ne sera pas décompté. \n\nSi vous lisez ou écoutez le même segment d'un livre plus d'une fois durant la même période, ou si deux profils ou plus lisent ou écoutent le même segment durant la même période, le temps ne sera compté qu'une fois.",
  "mypages.time_consumption.faq.intro": "Vos heures d'écoute se renouvellent à chaque période de paiement et sont partagées entre tous les écrans.",
  "mypages.time_consumption.faq.subtitle1": "Livres audio",
  "mypages.time_consumption.faq.subtitle2": "Livres numériques",
  "mypages.time_consumption.faq.title": "Comment calculons-nous le nombre d'heures que vous passez à lire ou à écouter ?",
  "mypages.time_limited.action.show_activity": "Afficher l'activité",
  "mypages.time_restricted.body": "Vos heures se rechargeront dans {period} {periodUnit} et sont partagées entre tous les profils.",
  "mypages.time_restricted.time_consumed": "{hour} h {min} min",
  "mypages.time_restricted.time_consumed_min": "{min} min",
  "mypages.time_restricted.time_read": "{consumed} heures sur {allowed}",
  "mypages.time_restricted.time_read.title": "Temps de lecture",
  "mypages.time_restricted.time_read_per_profile": "Temps de lecture par profil",
  "mypages.title": "Mon compte",
  "mypages.update_email.label_current_email": "Adresse e-mail actuelle",
  "mypages.update_email.label_current_password": "Mot de passe actuel",
  "mypages.update_email.label_new_email": "Nouvelle adresse e-mail",
  "mypages.update_email.label_new_password": "Nouveau mot de passe",
  "mypages.update_email.label_repeat_email": "Saisissez à nouveau votre nouvelle adresse e-mail",
  "mypages.update_email.label_repeat_password": "Saisissez une nouvelle fois le nouveau mot de passe",
  "mypages.update_email.title": "Modifier l'adresse e-mail",
  "mypages.update_info.snackbar.info_updated": "Vos informations ont été mises à jour.",
  "n_picker.list.monthly_hours_info_silver": "30 heures",
  "partner.collect_points.body": "Saisissez vos informations pour commencer.",
  "partner.collect_points.title": "Obtenir des {unit} avec Nextory",
  "payment.ideal.payment_option.label": "Comment voulez-vous payer ?",
  "providers.errors.free.cancel_login": "Un problème est survenu. Veuillez réessayer de vous connecter.",
  "reactivate_subscription.nonmember.title": "Vous avez déjà profité de votre essai gratuit et devrez donc payer.",
  "redeem.campaign.error_page.body": "Essayez un autre code ou {link} si vous pensez que nous avons fait une erreur.",
  "redeem.campaign.error_page.body.link": "contactez-nous",
  "redeem.campaign.error_page.invalid_code.title": "Nous ne reconnaissons pas ce code",
  "redeem.campaign.error_page.microcopy_continue_without_code": "Continuer sans code",
  "redeem.campaign.error_page.title.code_consumed": "Vous avez déjà utilisé ce code",
  "redeem.campaign.error_page.title.code_invalid": "Nous ne reconnaissons pas ce code.",
  "redeem.campaign.error_page.title.new_member": "Ce code ne peut être utilisé que par les nouveaux membres",
  "redeem.campaign.offer_summary.discount.body": "{percent} % de réduction pendant {period} {periodUnit}",
  "redeem.campaign.offer_summary.discount.summary": "{percent} % de réduction pendant {period} {periodUnit}, puis {price}/mois",
  "redeem.campaign.offer_summary.discount.unlimited.body": "{percent} % de réduction",
  "redeem.campaign.offer_summary.discount.unlimited.summary": "{percent} % de réduction, puis {price}/mois",
  "redeem.campaign.offer_summary.discount_free_days.body": "{freeDays} jours gratuits, puis {percent} % de réduction pendant {period} {periodUnit}",
  "redeem.campaign.offer_summary.discount_free_days.summary": "{freeDays} jours gratuits, puis {percent} % de réduction pendant {period} {periodUnit}",
  "redeem.campaign.offer_summary.discount_free_days.unlimited.body": "{freeDays} jours gratuits, puis {percent} % de réduction",
  "redeem.campaign.offer_summary.discount_free_days.unlimited.summary": "{freeDays} jours gratuits, puis {percent} % de réduction",
  "redeem.campaign.offer_summary.fixed.body": "{price}/mois pendant {period} {periodUnit}, quel que soit votre abonnement",
  "redeem.campaign.offer_summary.fixed.summary": "{price} par mois pendant {period} {periodUnit}",
  "redeem.campaign.offer_summary.fixed.unlimited.body": "{price}/mois, quel que soit votre abonnement",
  "redeem.campaign.offer_summary.fixed.unlimited.summary": "{price} par mois",
  "redeem.campaign.offer_summary.fixed_free_days.body": "{freeDays} jours gratuits, puis {price}/mois pendant {period} {periodUnit}, quel que soit votre abonnement",
  "redeem.campaign.offer_summary.fixed_free_days.summary": "{freeDays} jours gratuits puis payez {price} par mois pendant {period} {periodUnit}",
  "redeem.campaign.offer_summary.fixed_free_days.unlimited.body": "{freeDays} jours gratuits, puis {price}/mois, quel que soit votre abonnement",
  "redeem.campaign.offer_summary.fixed_free_days.unlimited.summary": "{freeDays} jours gratuits puis payez {price} par mois",
  "redeem.campaign.offer_summary.free_days.body": "Profitez-en gratuitement pendant {period} {periodUnit}",
  "redeem.campaign.offer_summary.free_days.summary": "Profitez-en gratuitement pendant {period} {periodUnit}, puis à {price}/mois",
  "redeem.campaign.offer_summary.title": "Votre offre",
  "redeem.campaign.title": "Saisissez votre code promotionnel.",
  "redeem.error.code_already_used": "Vous avez déjà utilisé ce code.",
  "redeem.error.code_expired": "Ce code a expiré.",
  "redeem.error.not_active_user": "Ce code ne fonctionne que si vous avez un abonnement actif.",
  "redeem.error.not_new_customer": "Ce code ne peut être utilisé que par les nouveaux membres.",
  "redeem.error.not_nonmember": "Ce code ne fonctionne que si vous avez annulé votre abonnement.",
  "redeem.gift_card.body": "Connectez-vous pour profiter de votre carte cadeau.",
  "redeem.gift_card.sub_picker.days": "Nombre de jours de lecture et d'écoute",
  "redeem.gift_card.sub_picker.days.title": "Votre carte cadeau vous donne",
  "redeem.gift_card.sub_picker.monthly_hour_period.title": "Nombre d'heures de lecture et d'écoute par période de 30 jours",
  "redeem.gift_card.sub_picker.non_paying.body": "Vous ne devrez effectuer aucun paiement après l'expiration de votre carte cadeau.",
  "redeem.gift_card.sub_picker.non_paying.cta": "Choisir : {name}",
  "redeem.gift_card.sub_picker.paying.body": "Une fois votre carte cadeau expirée, vous paierez le prix mensuel de l'abonnement de votre choix.",
  "redeem.gift_card.success.account.setup.title": "Saisissez vos informations",
  "redeem.gift_card.success.body": "Personnalisez votre compte pour optimiser votre expérience dans l'application.",
  "redeem.gift_card.success.cta": "Personnaliser le compte",
  "redeem.gift_card.success.title": "Votre carte cadeau a été activée !",
  "redeem.gift_card.title": "Saisissez le code de votre carte cadeau",
  "redeem.giftcard.error.already_redeemed": "Vous avez déjà utilisé cette carte cadeau.",
  "redeem.giftcard.error.expired": "Cette carte cadeau a expiré.",
  "redeem.giftcard.error.invalid_giftcard": "Nous ne reconnaissons pas cette carte cadeau. Contactez-nous si vous pensez que nous avons fait une erreur.",
  "redeem.giftcard.label": "Code de la carte cadeau",
  "redeem.giftcard.title": "Utilisez votre carte cadeau",
  "redeem.label": "Saisir le code",
  "redeem.title": "Utilisez votre carte cadeau ou votre code promotionnel",
  "referral.landing_page.body": "Disa vous a offert 60 jours gratuits. Vous pouvez lire et écouter vos livres où et quand vous voulez. Cette offre est exclusive aux nouveaux clients.",
  "referral.landing_page.button": "Essayez vos 60 jours gratuits",
  "referral.landing_page.title": "Récupérez votre essai gratuit",
  "regfunnel.account_creation.body": "Complétez votre profil pour personnaliser votre expérience.",
  "regfunnel.account_creation.button_save": "Sauvegarder et continuer",
  "regfunnel.account_creation.from_coupon": "Votre carte cadeau ou votre code promotionnel a bien été activé sur votre compte.",
  "regfunnel.account_creation.module.label_female": "Femme",
  "regfunnel.account_creation.module.label_firstname": "Prénom",
  "regfunnel.account_creation.module.label_male": "Homme",
  "regfunnel.account_creation.module.label_nonbinary": "Non binaire",
  "regfunnel.account_creation.module.label_phone": "Numéro de téléphone",
  "regfunnel.account_creation.module.label_surname": "Nom de famille",
  "regfunnel.account_creation.module.profile_title": "Votre profil|Ajouter un autre profil",
  "regfunnel.account_creation.module.tooltip_phone": "Nous ne l'utiliserons que pour récupérer un compte perdu.",
  "regfunnel.account_creation.module_title": "Vos informations",
  "regfunnel.account_creation.title": "Bienvenue sur Nextory !",
  "regfunnel.change_subscription.legacy.current_subscription": "Vous avez actuellement {name} pour {price}/mois.",
  "regfunnel.change_subscription.legacy.price": "Prix par mois",
  "regfunnel.change_subscription.legacy.title": "Nous avons de nouveaux abonnements",
  "regfunnel.change_subscription.legacy.usp": "Lire et écouter de manière illimitée",
  "regfunnel.create_account.body": "Plus que deux étapes, et c'est terminé ! La paperasse, ça ne nous plaît pas non plus.",
  "regfunnel.create_account.input_label_email": "E-mail",
  "regfunnel.create_account.input_label_password": "Choisissez un mot de passe",
  "regfunnel.create_account.input_label_repeat_email": "Entrez à nouveau votre adresse e-mail",
  "regfunnel.create_account.microcopy_step": "Étape {current} sur {last}",
  "regfunnel.create_account.microcopy_step2": "Étape 2 sur 3",
  "regfunnel.create_account.microcopy_step3": "Étape 3 sur 3",
  "regfunnel.create_account.module.disclaimer": "Inscrivez-vous à notre newsletter pour profiter de nos offres ainsi que de nos recommandations de lecture.",
  "regfunnel.create_account.module.title": "Créez votre compte",
  "regfunnel.create_account.title": "Inscrivez-vous pour accéder à votre offre",
  "regfunnel.create_account.title.no_free_trial": "Inscrivez-vous pour accéder à votre offre",
  "regfunnel.email.error.incorrect_email_address": "Vérifiez l'adresse e-mail.",
  "regfunnel.limited_free_trial.body": "Pendant votre période d'essai gratuit de {period} {periodUnit}, vous pouvez lire et écouter pendant {hours} heures au total.",
  "regfunnel.limited_free_trial.payment_summary.body": "{period} {periodUnit} gratuits avec {hours} heures de lecture et d'écoute",
  "regfunnel.non_member.starting_screen.body": "Vous avez déjà profité de votre essai gratuit, vous devrez donc payer dès que vous réactiverez votre abonnement.",
  "regfunnel.non_member.summary.price_starting_today": "{price}/mois, à partir d’aujourd’hui",
  "regfunnel.payment.button_start_free_trial": "Commencez votre essai gratuit",
  "regfunnel.payment.button_subscribe": "S'abonner",
  "regfunnel.payment.info_bulletpoint_cancellation": "Sans engagement. Annulez à tout moment.",
  "regfunnel.payment.info_bulletpoint_device": "Lisez et écoutez sur votre mobile et votre tablette.",
  "regfunnel.payment.info_bulletpoint_price": "Annulez avant le {date} et vous ne paierez rien.",
  "regfunnel.payment.module.card_cvv_placeholder": "123",
  "regfunnel.payment.module.card_expiry_placeholder": "MM/AA",
  "regfunnel.payment.module.card_nr_placeholder": "1111 2222 3333 4444",
  "regfunnel.payment.module.change_plan": "Modifier",
  "regfunnel.payment.module.error": "Votre paiement n’a pas été effectué. Vérifiez vos informations de paiement.",
  "regfunnel.payment.module.free_period": "{days} jour gratuit | {days} jours gratuits",
  "regfunnel.payment.module.label_card_cvv": "Cryptogramme visuel",
  "regfunnel.payment.module.label_card_expiry": "Date d'expiration",
  "regfunnel.payment.module.label_card_nr": "Numéro de carte",
  "regfunnel.payment.module.microcopy.disclaimer_info1": "Pour nous assurer que vous êtes une vraie personne, en chair et en os.",
  "regfunnel.payment.module.microcopy.disclaimer_info2": "Pour vous permettre de profiter directement de votre offre.",
  "regfunnel.payment.module.microcopy.disclaimer_payment": "Votre compte ne sera pas débité pendant votre essai gratuit. Vous pouvez annuler votre abonnement à tout moment.",
  "regfunnel.payment.module.microcopy.disclaimer_payment.no_free_trial": "Vous pouvez annuler votre abonnement à tout moment.",
  "regfunnel.payment.module.microcopy.disclaimer_title": "Pourquoi vous demander vos informations de paiement ?",
  "regfunnel.payment.module.microcopy_secure_server": "Serveur sécurisé (cryptage SSL)",
  "regfunnel.payment.module.price_info": "{pricePerMonth}/mois, à partir du {startingDate}",
  "regfunnel.payment.payment_method_trustly": "Trustly",
  "regfunnel.payment.title": "Paiement",
  "regfunnel.plan_picker.body": "Vous êtes libre de changer d'abonnement à tout moment.",
  "regfunnel.plan_picker.expired_or_invalid_coupon": "Votre code promotionnel ou votre carte cadeau n'est plus valide. Choisissez une offre parmi celles proposées ci-dessous ou contactez-nous.",
  "regfunnel.plan_picker.list.audiobooks_title": "Livres audio",
  "regfunnel.plan_picker.list.cancel_title": "Annulez quand vous le souhaitez",
  "regfunnel.plan_picker.list.device_title": "Mobile et tablette",
  "regfunnel.plan_picker.list.languages_title": "Plusieurs langues",
  "regfunnel.plan_picker.list.monthly_hours_info_gold_family": "Illimité",
  "regfunnel.plan_picker.list.monthly_hours_title": "Nombre d'heures de lecture et d'écoute par mois",
  "regfunnel.plan_picker.list.monthly_price_info_silver": "9,99€",
  "regfunnel.plan_picker.list.monthly_price_title": "Prix mensuel quand la période d'essai se termine, le 22 février 2022",
  "regfunnel.plan_picker.list.offline_mode_title": "Mode hors-ligne",
  "regfunnel.plan_picker.list.simultaneous_devices_title": "Nombre d'écrans simultanés",
  "regfunnel.plan_picker.list.simultaneous_profiles_info_family": "2-4",
  "regfunnel.plan_picker.list.simultaneous_profiles_title": "Nombre d'écrans pouvant utiliser l'application en même temps",
  "regfunnel.plan_picker.title": "Choisissez l'abonnement qui vous convient",
  "regfunnel.plan_picker_family.2profiles_price": "199 kr par mois",
  "regfunnel.plan_picker_family.3profiles_price": "239 kr par mois",
  "regfunnel.plan_picker_family.4profiles_price": "279 kr par mois",
  "regfunnel.plan_picker_family.title": "Combien de personnes liront ou écouteront des livres en même temps ?",
  "regfunnel.pre_create_account.body": "Pour commencer à lire et écouter, vous devez créer un compte.",
  "regfunnel.pre_create_account.button_create_account": "Créer un compte",
  "regfunnel.pre_create_account.title": "Créer un nouveau compte",
  "regfunnel.pre_plan_picker.body_first_bulletpoint": "Vous n'aurez rien à payer jusqu'à la fin de votre essai gratuit",
  "regfunnel.pre_plan_picker.body_first_bulletpoint.klm": "Obtenez 500 Miles une fois votre essai terminé",
  "regfunnel.pre_plan_picker.body_second_bulletpoint": "Lisez et écoutez sur votre mobile et sur votre tablette.",
  "regfunnel.pre_plan_picker.body_second_bulletpoint.klm": "Obtenez des Miles tous les mois",
  "regfunnel.pre_plan_picker.body_third_bulletpoint": "Annulez quand vous le souhaitez",
  "regfunnel.pre_plan_picker.title": "Choisissez votre abonnement",
  "regfunnel.profiles.body": "Vous pouvez créer jusqu'à {n} profils supplémentaires pour vos proches. Chaque profil bénéficie de ses propres recommandations.",
  "regfunnel.profiles.body.ab_test": "Créez jusqu'à 3 profils supplémentaires pour vos proches. Chaque profil bénéficie de ses propres recommandations et statistiques de lecture.",
  "regfunnel.profiles.button_send_link": "Envoyer un lien de connexion",
  "regfunnel.profiles.label_name": "Prénom",
  "regfunnel.profiles.login_link_body": "Envoyez un lien de connexion à la personne pour laquelle vous avez créé un profil. Ainsi, elle n'aura pas besoin d'entrer d'adresse e-mail ou de mot de passe.",
  "regfunnel.profiles.login_link_title": "Qu'est-ce que c'est ?",
  "regfunnel.profiles.module.title": "Créer un profil",
  "regfunnel.profiles.module_edit_profile.title": "Modifier le profil",
  "regfunnel.profiles.profile_info_non_family": "Votre abonnement permet de lire et d'écouter sur un seul écran à la fois. Optez pour un abonnement Famille si vous souhaitez que Nextory puisse être utilisé sur plusieurs écrans en simultané.",
  "regfunnel.profiles.snackbar_link_copied": "Le lien a été copié.",
  "regfunnel.profiles.title": "Partagez votre profil avec vos proches",
  "regfunnel.providers.errors.free.create_account_first_body": "Inscrivez-vous à Nextory pour vous connecter avec Free.",
  "regfunnel.providers.errors.free.create_account_first_cta": "Inscription",
  "regfunnel.providers.free_subtitle": "Afin de bénéficier de l'offre exclusive Free, merci de vous identifier avec les identifiants de votre compte Free ou Free Mobile.",
  "regfunnel.providers.free_title": "Saisissez les identifiants de votre compte Free",
  "regfunnel.providers.login_with_free": "Connexion avec Free",
  "regfunnel.registration_complete.action_get_in_touch_link": "Nous contacter",
  "regfunnel.registration_complete.action_get_in_touch_text": "Des questions ?",
  "regfunnel.registration_complete.module_desktop_body": "Sinon, téléchargez l'application et demandez au propriétaire du compte de se connecter.",
  "regfunnel.registration_complete.module_desktop_title": "Ouvrez ce lien sur votre téléphone ou sur votre tablette pour vous connecter directement",
  "regfunnel.registration_complete.module_download_app_title": "Téléchargez l'application pour découvrir votre premier livre",
  "regfunnel.returning_member.body": "Votre essai gratuit arrive à son échéance, mais vous pouvez continuer d'utiliser Nextory au prix standard.",
  "regfunnel.returning_member.disclaimer": "Je comprends que cette période d'essai gratuit n'est pas renouvelable et que je devrai payer un abonnement.",
  "regfunnel.returning_member.title": "Heureux de vous revoir !",
  "regfunnel.success.screen_with_qr.body": "Télécharger l'application en scannant le code.",
  "register.already_have_account.text": "Vous avez déjà un compte ?",
  "register.already_member.link": "Se connecter",
  "register.already_member.text": "Déjà membre ?",
  "search.ghostcopy": "Que souhaiteriez-vous lire ?",
  "search.no_result.did_you_mean": "Vous voulez peut-être dire",
  "search.no_result.suggested_fixes": "• Vérifiez l’orthographe\n• Essayez un autre mot",
  "search.no_results": "Hmm, nous n’avons pas pu trouver « Hry potr ».",
  "search.page": "Page 1 sur 34",
  "signup.error_email_already_exists": "Nous reconnaissons cette adresse e-mail. Souhaitez-vous vous connecter ?",
  "simultaneous_profiles.override.consumed_hours.faq.body": "Vous pouvez écouter et lire jusqu'à 30 heures par période de paiement. Les heures sont partagées entre tous les profils. Si vous souhaitez lire et écouter pendant plus de 30 heures, vous pouvez passer à un abonnement Premium ou Famille.",
  "simultaneous_profiles.override.consumed_hours.faq.title": "Il ne me reste plus d'heures. Que dois-je faire ?",
  "simultaneous_profiles.override.logged_out.faq.body": "Vous pouvez ajouter jusqu'à 4 profils, mais un seul profil peut utiliser l'application à la fois. Cela signifie que si le profil 1 utilise l'application et que le profil 2 se connecte, le profil 1 sera déconnecté. Si vous souhaitez que plusieurs profils puissent utiliser l'application en même temps, vous pouvez passer à un abonnement Famille.",
  "simultaneous_profiles.override.logged_out.faq.title": "Pourquoi ai-je été déconnecté ?"
})