import axios, { AxiosInstance, AxiosResponse, Method } from 'axios'
import consola, { LogLevel } from 'consola'

// @ts-ignore
import { version } from '~/package'

import { RequestInterface } from './interfaces/request'

const logger = consola.withScope('Hummingbird (Nextory)')

export class Request implements RequestInterface {
  public readonly http: AxiosInstance
  private deviceId: string | null = null
  private authToken: string | null = null
  private profileToken: string | null = null
  private logLevel: LogLevel

  constructor(
    private baseURL: string,
    private countryCode: string | undefined = undefined, // Format: "SE"
    private locale: string | undefined = undefined // Format: "sv"
  ) {
    logger.debug('Hummingbird created (modern):', {
      version,
      baseURL,
      countryCode,
      locale,
    })
    this.logLevel = LogLevel.Info
    this.http = this.prepareHttpClient()
  }

  private prepareHttpClient() {
    const headers = {
      'Content-Type': 'application/json',
      'X-Application-Id': '203', // Web
      'X-Device-Id': this.deviceId,
      'X-Model': 'web', // TODO: Get User Agent from request
      'X-App-Version': version,
      'X-Country-Code': this.countryCode,
      'X-Locale': `${this.locale}_${this.countryCode}`, // Format locale_COUNTRY, e.g. sv_SE
    }

    logger.debug('Hummingbird headers (modern):', headers)

    // TODO: Add interceptor for logging
    return axios.create({
      baseURL: this.baseURL,
      headers,
    })
  }

  _setDeviceId(deviceId: string) {
    this.deviceId = deviceId
    if (this.http) {
      this.http.defaults.headers['X-Device-Id'] = deviceId
    }
  }

  _setAuthToken(authToken: string | null) {
    this.authToken = authToken

    if (authToken) {
      this.http.defaults.headers['X-Login-Token'] = authToken
    }
  }

  _setProfileToken(profileToken: string | null) {
    this.profileToken = profileToken

    if (profileToken) {
      this.http.defaults.headers['X-Profile-Token'] = profileToken
    }
  }

  _setCountry(country: string | undefined) {
    this.countryCode = country

    if (this.countryCode) {
      logger.debug('Setting X-Country-Code to', `[${country}]`)
      this.http.defaults.headers['X-Country-Code'] = country
    } else {
      logger.debug('Removing X-Country-Code')
      delete this.http.defaults.headers['X-Country-Code']
    }
  }

  _setLanguage(locale: string) {
    this.locale = locale.substring(0, 2)

    logger.debug('Setting X-Locale to', `[${locale}]`)
    this.http.defaults.headers['X-Locale'] = locale
  }

  _setLogLevel(logLevel: LogLevel) {
    this.logLevel = logLevel
    logger.level = logLevel
  }

  buildUrl(url: string) {
    return `${this.baseURL}/${url}`
  }

  send(method: Method, endpoint: string, params: object = {}) {
    return this.apiCall(method, endpoint, params)
  }

  private async apiCall(
    method: Method,
    endpoint: string,
    params: object
  ): Promise<AxiosResponse> {
    // When the data is not found in the cache then we can make request to the server
    const req = await this.http({
      method,
      url: endpoint,
      params,
    })

    // Create a cachable object for subsequent request
    const res = JSON.stringify({
      data: req.data,
      headers: req.headers,
    })

    return new Promise(function (resolve) {
      resolve(JSON.parse(res))
    })
  }
}
