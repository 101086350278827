import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { MediaType } from './types/mediaType'

export default class MediaTypes extends BaseApi {
  getMediaTypes(): AxiosPromise<MediaType[]> {
    return this.send('get', '/media_types')
  }
}
