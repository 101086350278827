import { AxiosPromise } from 'axios'

import { UpdateProfile } from '../api-types/Profile'
import LegacyMe from '../legacy-api/me'
import { Profile as LegacyProfile } from '../legacy-api/types/profile'
import { PaymentInfos, Subscription } from '../legacy-api/types/subscription'

import BaseApi from './baseApi'
import {
  imitateFreeLegacyAccount,
  modernAccountSubscriptionToLegacySubscription,
  subscriptionToPaymentInfo,
} from './converters/meSubscription'
import { modernAccountToLegacyProfile } from './converters/profileToLegacyProfile'
import convert from './helpers/convert'

export default class Me
  extends BaseApi
  // partially implementing this lets us use TypeScript's type inference on bridge methods
  implements Partial<LegacyMe>
{
  /**
   * Returns the current user information.
   *
   * Note: For Nextory's API, it'll be the Account.
   */
  getProfile(): AxiosPromise<LegacyProfile> {
    return convert(
      this.http({
        method: 'get',
        url: `/user/v1/me/account`,
      }),
      modernAccountToLegacyProfile
    )
  }

  updateProfile(data: { profile: Partial<UpdateProfile> }) {
    return this.http({
      method: 'patch',
      url: '/user/v1/me/account',
      data: data.profile,
    })
  }

  updatePassword(data: {
    current_password: string // The user's current password
    password: string // The new user's password
    password_confirmation: string // The new user's password confirmation (must match the password)
  }): AxiosPromise<void> {
    return convert(
      this.http({
        method: 'patch',
        url: `/user/v1/me/password`,
        data: {
          current_password: data.current_password,
          new_password: data.password,
        },
      })
    )
  }

  /**
   * Replace payment method on NX5
   * @see adyenSubscription.ts > createSubscription (similar endpoint)
   */
  addPaymentMethod(tmpData: {
    browserInfo: any // from Adyen, not explicitly typed
    paymentMethod: any // from Adyen, not explicitly typed
    riskData: any // from Adyen, not explicitly typed
  }) {
    // TODO: Ask back-end team to ignore "brand" and "type"
    const { brand, type, ...paymentInfo } = tmpData.paymentMethod

    const data = {
      gateway: 'ADYEN',
      payment_info: paymentInfo,
      // TODO: It's going to be browser_info soon
      browserInfo: tmpData.browserInfo,
    }

    return this.http({
      method: 'put',
      url: `/obgateway/v1/me/payment_infos`,
      data,
    })
  }

  reactivateMeSubscription(): AxiosPromise<void> {
    return this.http({
      method: 'post',
      url: '/obgateway/v1/me/subscription/recover',
    })
  }

  /**
   * Unsubscribe the connected user
   */
  deleteProfileSubscription(data: {
    comment: string
    reason: string // required
  }): AxiosPromise<void> {
    return this.http({
      method: 'post',
      url: '/obgateway/v1/me/subscription/cancel',
      data,
    })
  }

  /**
   * Retrieve user payment method information
   *
   * Optimization needed: On NX5, we already have this information in getProfileSubscription.
   * This method will need to be removed once legacy API is deleted.
   */
  getUserPaymentInfos(): AxiosPromise<PaymentInfos[]> {
    return convert(
      this.http({
        method: 'get',
        url: `/obgateway/v1/me/subscription`,
      }),
      subscriptionToPaymentInfo
    ).catch(() => {
      return new Promise(resolve => {
        resolve({
          // if NX5 API raises an error, return an empty array to make it look like legacy API (empty array of cards)
          data: [],
          config: {},
          headers: {},
          status: 200,
          request: {},
          statusText: 'OK',
        })
      })
    })
  }

  /**
   * Returns information about the connected user active subscription
   */
  getProfileSubscription(): AxiosPromise<Subscription | ''> {
    return convert(
      this.http({
        method: 'get',
        url: `/obgateway/v1/me/subscription`,
      }),
      modernAccountSubscriptionToLegacySubscription
    ).catch(() => {
      return new Promise(resolve => {
        resolve({
          // if NX5 API raises an error, return a "free" AccountSubscription to make it look like legacy API
          // (yes, it was an empty string 😱)
          data: '',
          config: {},
          headers: {},
          status: 200,
          request: {},
          statusText: 'OK',
        })
      })
    })
  }
}
