// Import i18n header from mixin since it's not handle in nuxt config with generate command

export default {
  head() {
    let i18nHead = undefined
    if (this?.$nuxtI18nHead) {
      i18nHead = this.$nuxtI18nHead({
        addSeoAttributes: true,
        addDirAttribute: true,
      })
    }

    return {
      title: this.$t('meta.default.title'),
      htmlAttrs: { ...i18nHead?.htmlAttrs },
      meta: [
        ...(i18nHead?.meta || []),
        {
          property: 'og:title',
          content: this.$t('meta.default.title'),
          hid: 'ogTitle',
        },
        {
          property: 'og:description',
          content: this.$t('meta.default.description', {
            trialDays: this.$hummingbird.marketProperties.trialDays,
          }),
          hid: 'ogDescription',
        },
        {
          name: 'twitter:description',
          content: this.$t('meta.default.description', {
            trialDays: this.$hummingbird.marketProperties.trialDays,
          }),
          hid: 'twitterDescription',
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.default.description', {
            trialDays: this.$hummingbird.marketProperties.trialDays,
          }),
        },
      ],
      link: [...(i18nHead?.link || [])],
    }
  },
}
