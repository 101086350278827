import { render, staticRenderFns } from "./transparentHeader.vue?vue&type=template&id=4d55861c&"
import script from "./transparentHeader.vue?vue&type=script&lang=js&"
export * from "./transparentHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NxHeader: require('/app/packages/nx-components/src/NxHeader.vue').default,NxFooter: require('/app/packages/nx-components/src/NxFooter.vue').default})
