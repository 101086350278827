/**
 * This development middleware is here to alleviate some issues we're having while running each project on different ports.
 *
 * Before, when you tried to go to an invalid URL, like <profile-host>/catalog, you would have a 404.
 * This middleware detects those issues and redirect to the proper URL (in our example: <catalog-host>/catalog)
 */
export default async ({ app: { $link }, redirect, route: { fullPath } }) => {
  if (['production', 'staging'].includes(process.env.ENVIRONMENT)) return

  const crossLink = $link.getCrossLink(fullPath)
  if (crossLink) {
    await redirect(crossLink)
  }
}
