import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { AuthorWithRelation } from './types/author'
import { LinkedAccount, LinkedAccountProvider } from './types/linkedAccount'
import { Profile } from './types/profile'
import { PaymentInfos, Subscription } from './types/subscription'

export default class Me extends BaseApi {
  /**
   * Returns the current user information.
   *
   * Note: For Nextory's API, it'll be the Account.
   */
  getProfile(): AxiosPromise<Profile> {
    return this.http({
      method: 'get',
      url: `/me/profile`,
    })
  }

  updateProfile(data: { profile: Partial<Profile> }) {
    return this.http({
      method: 'patch',
      url: '/me/profile',
      data,
    })
  }

  /**
   * Allow to update the user's password
   */
  updatePassword(data: {
    current_password: string // The user's current password
    password: string // The new user's password
    password_confirmation: string // The new user's password confirmation (must match the password)
  }): AxiosPromise<void> {
    return this.http({
      method: 'patch',
      url: `/me/password`,
      data,
    })
  }

  /**
   * Returns information about the connected user active subscription
   */
  getProfileSubscription(): AxiosPromise<Subscription | ''> {
    return this.http({
      method: 'get',
      url: '/me/subscription',
    })
  }

  /**
   * Subscribe the connected user to an offer for apple/google/huawei/free/youboox
   */
  postProfileSubscription(
    data:
      | {
          provider: 'apple'
          receipt: string // The Base 64 receipt of the purchase
        }
      | {
          package_name: string // The package name of the application for which this subscription was purchased
          provider: 'google'
          purchase_token: string // The token provided to the user's device when the subscription was purchased.
          subscription_id: string // The purchased subscription ID
          token: string
        }
      | {
          package_name: string // The package name of the application for which this subscription was purchased
          provider: 'huawei'
          purchase_token: string // The token provided to the user's device when the subscription was purchased.
          subscription_id: string // The purchased subscription ID
        }
      | {
          code: string
          provider: 'youboox' // Coupon code for activating a subscription
        }
  ) {
    return this.http({
      method: 'post',
      url: '/me/subscription',
      data,
    })
  }

  /**
   * Unsubscribe the connected user
   * comment & reason are for NX5
   */
  deleteProfileSubscription(data: {
    comment: string
    reason: string
  }): AxiosPromise<void> {
    return this.http({
      method: 'delete',
      url: '/me/subscription',
    })
  }

  /**
   * Return every external accounts of the connected user
   */
  getLinkedAccount(): AxiosPromise<LinkedAccount[]> {
    return this.http({
      method: 'get',
      url: '/me/linked_accounts',
    })
  }

  /**
   * Associate an external account to the connected user.
   */
  postLinkedAccount(data: {
    access_token: string // Token provided by external service to identify the user
    provider: LinkedAccountProvider // Name of the external service
  }) {
    return this.http({
      method: 'post',
      url: '/me/linked_accounts',
      data,
    })
  }

  /**
   * Allow a user to purchase a book.
   */
  purchaseBook(bookId: number): AxiosPromise<{
    message: string // Message that confirm the purchase has been done
  }> {
    return this.http({
      method: 'post',
      url: `/me/books/${bookId}/purchase`,
    })
  }

  /**
   * Retrieve both author information and follow/like relation with current user (in relations_with_user field)
   */
  fetchAuthor(authorId: number): AxiosPromise<AuthorWithRelation> {
    return this.http({
      method: 'get',
      url: `/me/authors/${authorId}`,
    })
  }

  /**
   * Allow the current user to follow the requested author
   */
  followAuthor(authorId: number) {
    return this.http({
      method: 'post',
      url: `/me/authors/${authorId}/follow`,
    })
  }

  /**
   * Allow the current user to unfollow the requested author
   */
  unfollowAuthor(authorId: number) {
    return this.http({
      method: 'delete',
      url: `/me/authors/${authorId}/follow`,
    })
  }

  /**
   * Retrieve user payment method information
   */
  getUserPaymentInfos(): AxiosPromise<PaymentInfos[]> {
    return this.http({
      method: 'get',
      url: `/me/payment_infos`,
    })
  }

  /**
   * Add a new payment method
   */
  addPaymentMethod(data: {
    browserInfo: object // From Adyen
    paymentMethod: object // From Adyen
    riskData: object // From Adyen
  }) {
    return this.http({
      method: 'post',
      url: `/me/payment_infos`,
      data,
    })
  }

  /**
   * Delete a payment method
   */
  deletePaymentMethod(paymentMethodId: number): AxiosPromise<{
    paymentInfos: {
      cardSummary: string // Card summary
      expiryDate: string // Expiry date
    }
    resultCode: string // Result code
  }> {
    return this.http({
      method: 'delete',
      url: `/me/payment_infos/${paymentMethodId}`,
    })
  }
}
