//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImgixClient from '@imgix/js-core'

export default {
  props: {
    height: { type: String, default: '128' },
    width: { type: String, default: '128' },
    src: { type: String, required: true },
    alt: { type: String, required: true },
  },

  data() {
    return {
      imgLoad: false,
      options: {
        w: this.width,
        h: this.height,
        auto: 'format',
      },
      ixSrcset: null,
      ixSrc: null,
    }
  },

  fetch() {
    const ixClient = new ImgixClient({
      domain: 'nextory-asserts.imgix.net',
    })
    this.ixSrcset = ixClient.buildSrcSet(this.src, this.options)
    this.ixSrc = ixClient.buildURL(this.src, this.options)
  },
}
