import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { Book } from './types/book'
import { PaginationParams } from './types/common'

export default class Books extends BaseApi {
  getBook(id: number): AxiosPromise<Book> {
    return this.send('get', `/books/${id}`)
  }

  /**
   * Returns books that are part of the same series as the current books.
   * Current book is excluded from the results.
   */
  getBookFromSameSeries(
    bookId: number,
    params: PaginationParams
  ): AxiosPromise<Book[]> {
    return this.send('get', `/books/${bookId}/from_same_series`, params)
  }
}
