import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { Comment } from './types/comment'
import { PaginationParams } from './types/common'

export default class Comments extends BaseApi {
  getBookComments(
    bookId: string,
    params: {
      with_text?: boolean // only comments with text
    } & PaginationParams
  ): AxiosPromise<Comment[]> {
    return this.http.get(`/books/${bookId}/comments`, { params })
  }
}
