import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { Profile } from './types/profile'

export default class Registration extends BaseApi {
  signUp(data: {
    user: {
      accept_newsletter?: boolean
      country?: string // ISO 3166-1 alpha-2
      email: string
      languages?: string[]
      password: string
    }
  }): AxiosPromise<Profile> {
    return this.http({
      method: 'post',
      url: `/registrations`,
      data,
    })
  }
}
