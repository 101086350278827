import { AxiosPromise } from 'axios'

import BaseApi from '../baseApi'

/**
 * External endpoint for Digital Virgo (Orange Tunisie) connexion/subscription
 *
 * 🔺 Not documented... Had to check how it's used in the app.
 */
export default class DigitalVirgo extends BaseApi {
  /**
   * Add endpoint to get a DigitalVirgo authentication token
   */
  getAuthenticationToken(attributes: {
    phone_number: string // user's phone number
    service_id: string // gateway's uuid to subscribe user
  }): AxiosPromise<{
    token: string // DigitalVirgo authentication token
  }> {
    return this.http({
      method: 'post',
      url: '/external/digital_virgo/authentication_token',
      data: { authentication_token: attributes },
    })
  }

  /**
   * Add endpoint to get a Youboox access token
   */
  getYoubooxAccessToken(attributes: { session_id: string }): AxiosPromise<{
    access_token: string // Youboox access token
  }> {
    return this.http({
      method: 'post',
      url: '/external/digital_virgo/access_tokens',
      data: { access_token: attributes },
    })
  }

  /**
   * Implements endpoint asking for a pin code
   */
  sendPinCode(attributes: { phone_number: string }) {
    return this.http({
      method: 'post',
      url: '/external/digital_virgo/pin_codes',
      data: { pin_code: attributes },
    })
  }

  /**
   * Implements endpoint verifying a pin code
   */
  verifyPinCode(
    pinCode: number,
    attributes: {
      phone_number: string
      subscription_id: string
    }
  ): AxiosPromise<{
    access_token: string // Youboox access token
  }> {
    return this.http({
      method: 'patch',
      url: `/external/digital_virgo/pin_codes/${pinCode}`,
      data: { pin_code: attributes },
    })
  }

  /**
   * External subscription url endpoint
   */
  subscriptionUrl(): string {
    return this.request.buildUrl('/external/digital_virgo/subscriptions')
  }
}
