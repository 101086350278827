export default {
  created() {
    this.trackPageView()
  },

  watch: {
    '$route.path'() {
      this.trackPageView()
    },
  },

  methods: {
    trackPageView() {
      if (!process.client) return

      let originalLocation = localStorage.getItem('originalLocation.url')
      let originalLocationExpireTime =
        localStorage.getItem('originalLocation.expires') || 0

      if (!originalLocation || originalLocationExpireTime < Date.now()) {
        // first visit or expired session

        originalLocation = window.location.href
        originalLocationExpireTime = Date.now() + 30 /* minutes */ * 1000 * 60

        localStorage.setItem('originalLocation.url', originalLocation)
        localStorage.setItem(
          'originalLocation.expires',
          originalLocationExpireTime
        )
      }

      this.$gtm?.push({
        event: 'virtualPageview',
        virtualURL: window.location.pathname,
        originalLocation,
      })

      setTimeout(() => {
        this.$gtm?.push({
          event: 'virtualPageviewThirdParty',
          virtualURL: window.location.pathname,
          originalLocation,
        })
      }, 3000)
    },
  },
}
