import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { Book } from './types/book'
import { BookList, BooklistIdentifier } from './types/bookList'
import { PaginationParams } from './types/common'

export default class BooklistsBooks extends BaseApi {
  /**
   * Action that retrieve books from a list
   */
  fetchBookListBooks(
    identifier: BooklistIdentifier,
    params: PaginationParams
  ): AxiosPromise<Book[]> {
    return this.http({
      method: 'get',
      url: `/me/book_lists/${identifier}/books`,
      params,
    })
  }

  /**
   * Action that adds one book, many books, one series or many series to a list.
   * Yes, all of these can be added to a list. From a single endpoint. It's awesome.
   */
  addBookToList(
    identifier: BooklistIdentifier,
    data:
      | {
          // Adding a single book to a list
          book_id: number
          position?: number
        }
      | {
          // Adding multiple books to a list
          book_ids: number[]
        }
      | {
          // Adding every book of a single series to a list
          serie_id: number
        }
      | {
          // Adding every book of multiple series to a list
          series_id: number[]
        }
  ): AxiosPromise<BookList> {
    return this.http({
      method: 'post',
      url: `/me/book_lists/${identifier}/books`,
      data,
    })
  }

  /**
   * Action that removes a book from a list
   */
  removeBookFromList(
    identifier: BooklistIdentifier,
    bookId: number
  ): AxiosPromise<BookList> {
    return this.http({
      method: 'delete',
      url: `/me/book_lists/${identifier}/books/${bookId}`,
    })
  }
}
