//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import markets from '@nextory/config/markets'

import CrossLink from '../CrossLink'

export default {
  components: {
    CrossLink,
  },

  props: {
    hasNavIconsLight: { type: Boolean, default: false },
  },

  data() {
    return {
      isMenuExpanded: false,
      markets: [
        {
          country: '🇹🇳 Tunisia / Tunisie',
          code: 'fr',
          api: 'legacy',
          languages: [
            {
              locale: 'fr',
              label: 'FR',
            },
            {
              locale: 'fr-en',
              label: 'EN',
            },
          ],
        },
      ],
    }
  },

  computed: {
    // Current locale market is returned first depending on the country.
    sortedMarkets() {
      const currentCountry = this.$i18n.locale.slice(0, 2)
      const currentMarket = this.markets.find(
        market => market.code === currentCountry
      )
      // Remove current market from the list and sort the rest, ignoring country emojis.
      const marketName = market => market.country.replace(/\p{Emoji}/gu, '')
      const otherMarkets = this.markets
        .filter(market => market.code !== currentCountry)
        .sort((a, b) => marketName(a).localeCompare(marketName(b)))

      return [currentMarket, ...otherMarkets].filter(
        market =>
          // avoids errors when no market is found for the current locale
          typeof market !== 'undefined' &&
          // make sure market is available (based on i18n config)
          markets.includes(market.code.toUpperCase())
      )
    },
  },

  mounted() {
    window.addEventListener('click', this.close, false)
    window.addEventListener('focusin', this.close, false)
    window.addEventListener('keydown', this.close, false)
  },

  beforeDestroy() {
    window.removeEventListener('click', this.close, false)
    window.removeEventListener('focusin', this.close, false)
    window.removeEventListener('keydown', this.close, false)
  },

  methods: {
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.hideMenu()
      }

      if (e.key === 'Escape') {
        this.hideMenu()
      }
    },

    showMenu() {
      this.isMenuExpanded = true
    },

    hideMenu() {
      this.isMenuExpanded = false
    },
  },
}
