import { FeatureKeys } from '../../api-types/FeatureKeys'
import { Features } from '../../api-types/Features'
import { LegacyFeaturesList } from '../../api-types/LegacyFeaturesList'
import { OnboardingGateway } from '../../api-types/OnboardingGateway'
import { Subscription } from '../../api-types/Subscription'
import { SubscriptionVariant } from '../../api-types/SubscriptionVariant'
import {
  DeviceOpportunity,
  TranslatedFeatureKey,
  Feature as LegacyFeature,
} from '../../legacy-api/types/deviceOpportunity'

const PREFERED_OFFER = 'family'

const STANDARD_MAX_USERS = 1
const STANDARD_TIME_LIMIT = 15
const PREMIUM_MAX_USERS = 2
const PREMIUM_TIME_LIMIT = 30
const FAMILY_MAX_USERS = 4
const FAMILY_TIME_LIMIT = 50

function isMultiScreenKey(key: string): boolean {
  return key.includes('multi_screen')
}

function isTimeCreditKey(key: string): boolean {
  return key.includes('time_credit')
}

function getFeaturesKeysType(key: string) {
  if (isMultiScreenKey(key) || isTimeCreditKey(key)) {
    return 'string' as const
  } else {
    return 'boolean' as const
  }
}

function getFeaturesKeyName(key: string) {
  if (isMultiScreenKey(key)) {
    return 'multi_screen'
  } else if (isTimeCreditKey(key)) {
    return 'time_credit'
  } else {
    return key
  }
}

function getFeaturesKeyText(
  name: string,
  paramsModifier: TranslatedFeatureKey | undefined
): string | boolean {
  if (isMultiScreenKey(name)) {
    return paramsModifier?.multi_screen ?? ''
  } else if (isTimeCreditKey(name)) {
    return paramsModifier?.time_credit ?? ''
  } else {
    return false
  }
}

function getFeaturesKeyValue(feat: LegacyFeature): string | boolean {
  if (isMultiScreenKey(feat.key) || isTimeCreditKey(feat.key)) {
    return feat.name
  } else {
    return feat.status === 'available'
  }
}

function legacyFeaturesToModernFeaturesList(
  opportunity: LegacyFeature[]
): LegacyFeaturesList {
  const featuresList = opportunity.map(element => [
    getFeaturesKeyName(element.key),
    getFeaturesKeyValue(element),
  ])

  return Object.fromEntries(featuresList)
}

function legacyFeaturesToModernFeaturesKeys(
  opportunity: LegacyFeature[],
  paramsModifier: TranslatedFeatureKey | undefined
): FeatureKeys[] {
  // change the object key / value
  let features = opportunity.map(arr => {
    return {
      key: getFeaturesKeyName(arr.key),
      text: getFeaturesKeyText(arr.key, paramsModifier) || arr.name,
      type: getFeaturesKeysType(arr.key),
    }
  })

  // adding the missing price feature in first position
  features.unshift({
    key: 'price',
    text: paramsModifier?.feature_price_label ?? '',
    type: 'boolean',
  })

  return features
}

function legacyToModernFeaturesKeys(
  opportunities: DeviceOpportunity[],
  paramsModifier: TranslatedFeatureKey | undefined
): FeatureKeys[] {
  // remove all the hidden feature
  let obGateway = opportunities.flatMap(opportunity => {
    return opportunity.features.filter(feature => feature.status !== 'hidden')
  })

  // change the object key / value
  const obGatewayFeaturesKeys = legacyFeaturesToModernFeaturesKeys(
    obGateway,
    paramsModifier
  )

  // remove all the dupplicated object
  const removeDuplicateObGatewayFeaturesKeys = obGatewayFeaturesKeys.filter(
    (obj, index, arr) => {
      return arr.map(mapObj => mapObj.key).indexOf(obj.key) === index
    }
  )

  return removeDuplicateObGatewayFeaturesKeys
}

function timeLimit(name: string): number {
  switch (name) {
    case 'Standard':
      return STANDARD_TIME_LIMIT

    case 'Premium':
      return PREMIUM_TIME_LIMIT

    case 'Famille':
      return FAMILY_TIME_LIMIT

    default:
      return 0
  }
}

function maxUser(name: string): number {
  switch (name) {
    case 'Premium':
      return PREMIUM_MAX_USERS

    case 'Famille':
      return FAMILY_MAX_USERS

    default:
      return 1
  }
}

function legacyOfferToModernFeatures(
  opportunity: DeviceOpportunity
): LegacyFeaturesList {
  const removeHidden = opportunity.features.filter(
    feature => feature.status !== 'hidden'
  )

  const overrideFeaturesList = legacyFeaturesToModernFeaturesList(removeHidden)

  return {
    ...overrideFeaturesList,
    hours: '0',
    price: `${opportunity.gateway.offer.amount} ${opportunity.gateway.offer.currency}`,
  }
}

function legacyOfferToModernVariants(
  opportunity: DeviceOpportunity
): SubscriptionVariant[] {
  const variants = [
    {
      alias: opportunity.name.toUpperCase(),
      amount: Number(opportunity.gateway.offer.amount),
      is_preferred: false,
      max_login_count: 1,
      plan_name: opportunity.name,
      prefered: false,
      subscription_id: opportunity.gateway.uuid,
    },
  ]
  return variants
}

function legacyToModernSubscriptions(
  opportunities: DeviceOpportunity[]
): Subscription[] {
  const legacyToModernOpportunyName: { [id: string]: string } = {
    Famille: 'FAMILY',
    Premium: 'GOLD',
    Standard: 'SILVER',
    One: 'ONE',
  }

  const returnValue = opportunities
    .map(opportunity => {
      return {
        alias: `${legacyToModernOpportunyName[opportunity.name]}`,
        currency: 'EUR',
        features: legacyOfferToModernFeatures(opportunity),
        max_users: maxUser(opportunity.name),
        plan_name: opportunity.name,
        time_limit_hrs: timeLimit(opportunity.name),
        trial_days:
          opportunity.gateway.offer.number_of_days_before_first_payment,
        variants: legacyOfferToModernVariants(opportunity),
      }
    })
    .filter(opportunity => opportunity.alias !== 'ONE')

  return returnValue
}

export function legacyOpportunitiesToModernOnboardingGateway(
  legacyOpportunities: DeviceOpportunity[],
  paramsModifier: TranslatedFeatureKey | undefined
): OnboardingGateway {
  return {
    channel: 'NEXTORY',
    country_code: 'FR',
    feature_keys: legacyToModernFeaturesKeys(
      legacyOpportunities,
      paramsModifier
    ),
    subscriptions: legacyToModernSubscriptions(legacyOpportunities),
  }
}
