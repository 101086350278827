import consola from 'consola'

const logger = consola.withScope('Hummingbird (ApiProxy)')

export class ApiProxy<From, To> {
  constructor(
    public readonly apiVersionRef: { value: 'legacy' | 'modern' },
    public readonly legacyAccessor: From,
    public readonly modernAccessor: To
  ) {}
}

function proxyProperty<From, To>(
  propertyName: string,
  target: { [index: string]: any },
  apiVersion: 'legacy' | 'modern'
) {
  if (propertyName in target) {
    logger.debug(`Calling ${apiVersion} API method '${propertyName}'`)
    if (typeof target[propertyName] === 'function') {
      return target[propertyName].bind(target)
    } else {
      return target[propertyName]
    }
  } else {
    throw new Error(
      `Property or function ${propertyName} not found in ${apiVersion} API`
    )
  }
}

export function createApiProxy<From, To>(
  apiVersionRef: { value: 'legacy' | 'modern' },
  legacyAccessor: From,
  modernAccessor: To
): From | To {
  const apiProxy = new ApiProxy(apiVersionRef, legacyAccessor, modernAccessor)
  const handler = {
    get(
      target: ApiProxy<
        From extends { [index: string]: any } ? From : never,
        To extends { [index: string]: any } ? To : never
      >,
      propertyName: string
    ): any {
      const accessor =
        apiProxy.apiVersionRef.value === 'legacy'
          ? target.legacyAccessor
          : target.modernAccessor

      return proxyProperty(propertyName, accessor, apiProxy.apiVersionRef.value)
    },
  }

  // Note: Casting to unknown is required to avoid TypeScript error with the proxy.
  // In practice, we effectively have a "From | To" type.
  return new Proxy(apiProxy, handler) as unknown as From | To
}
