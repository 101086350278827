import CryptoJS from 'crypto-js'

interface Dictionary {
  [key: string]: string
}

export default class YbSignature {
  /**
   * @param {String} apiKey: API key to use to call the APIv2
   * @param {String} apiSecret: API secret to use to call the APIv2
   */
  constructor(public apiKey: string, public apiSecret: string) {}

  /**
   * Prepare request arguments for the generateSignature method
   *
   * @param {Object} args: args extracted from request
   */
  _preparedArguments(args: object): Dictionary {
    const preparedArgs: Dictionary = {}

    for (const [key, value] of Object.entries(args)) {
      if (Array.isArray(value)) {
        continue
      } else if (typeof value === 'object') {
        continue
      }

      preparedArgs[key] = value
    }

    return preparedArgs
  }

  /**
   * Order request arguments for the generateSignature method
   *
   * @param {Object} args : args extracted from request
   */
  _orderArguments(args: Dictionary): string {
    const orderedArgs: Dictionary = {}
    Object.keys(args)
      .sort()
      .forEach(function (key) {
        if (args[key] !== undefined) {
          orderedArgs[key] = args[key]
        }
      })

    let stringArgs = ''
    for (const key in orderedArgs) {
      if (stringArgs !== '') {
        stringArgs += '&'
      }
      stringArgs += key + '=' + orderedArgs[key]
    }

    return stringArgs
  }

  /**
   * Merge data query and body request arguments
   * @param {Object} params: Parameters of the request
   * @param {Object} data: Body of the request, default null for compatibility
   * @return {Object} Return undefined if no args
   */
  _mergeRequestArguments(
    params: object,
    data: object | undefined
  ): Dictionary | undefined {
    // We are using spread operator to handle undefined args
    let requestArgs: Dictionary | undefined = {
      ...params,
      ...data,
    }

    if (Object.keys(requestArgs).length === 0) {
      requestArgs = undefined
    }

    return requestArgs
  }

  /**
   * Generation of the Header signature
   *
   * @param {Number} timestamp: Timestamp in second
   * @param {String} requestURL: Url of the request without baseURL => /api/selections.js
   * @param {Object} parameters: Parameters of the request
   * @param {Object} data: Body of the request, default null for compatibility
   */
  generateSignature(
    timestamp: number,
    requestURL: string,
    parameters: object,
    data: object | undefined = undefined
  ) {
    let stringArgs = ''
    let requestPath = requestURL
    requestPath = requestPath.replace('?', '')
    requestPath = requestPath.replace(/\/+$/, '')

    let requestArgs = this._mergeRequestArguments(parameters, data)

    if (requestArgs !== undefined) {
      requestArgs = this._preparedArguments(requestArgs)

      stringArgs = this._orderArguments(requestArgs)
    }

    const dataString = requestPath + stringArgs + this.apiKey + timestamp

    return CryptoJS.enc.Base64.stringify(
      CryptoJS.HmacSHA1(dataString, this.apiSecret)
    )
  }
}
