// Nuxt uses Lodash templates to build this file, so the syntax is kinda weird but it's normal.
// @see https://nuxtjs.org/docs/internals-glossary/internals-module-container/#addplugin-template

export default (context, inject) => {
  // options are injected by Nuxt
  const options = JSON.parse('{"prefix":""}')
  const nxLocalePath = path => {
    const prefix = options.prefix ?? ''

    if (typeof path === 'string') {
      return context.localePath(prefix + path) // eslint-disable-line no-restricted-syntax
    } else if (typeof path === 'object') {
      return context.localePath(path) // eslint-disable-line no-restricted-syntax
    } else {
      return ''
    }
  }
  inject('nxLocalePath', nxLocalePath)
}
