import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { BookReading } from './types/bookReading'

export default class BookReadings extends BaseApi {
  /**
   * Returns a book reading for a book
   */
  fetchBookReading(bookId: number): AxiosPromise<BookReading> {
    return this.http({
      method: 'get',
      url: `/me/books/${bookId}/book_reading`,
    })
  }
}
