import consola from 'consola'
// @ts-ignore Lodash merge doesn't export types
import merge from 'lodash.merge'

import { Orders } from './api/Orders'
import { Profiles } from './api/Profiles'
import { Receipts } from './api/Receipts'
import AdyenSubscription from './bridge-api/adyenSubscription'
import { DeviceOpportunities } from './bridge-api/deviceOpportunities'
import Me from './bridge-api/me'
import Registration from './bridge-api/registrations'
import Sessions from './bridge-api/sessions'
import Users from './bridge-api/users'
import { createApiProxy } from './bridge/ApiProxy'
import {
  defaultProperties,
  MarketProperties,
  propertiesPerMarkets,
} from './interfaces/marketProperties'
import LegacyAccessTokens from './legacy-api/accessTokens'
import LegacyAdyenSubscription from './legacy-api/adyenSubscription'
import LegacyAuthors from './legacy-api/authors'
import LegacyBookLists from './legacy-api/bookLists'
import LegacyBooklistsBooks from './legacy-api/booklistsBooks'
import LegacyBookReadings from './legacy-api/bookReadings'
import LegacyBooks from './legacy-api/books'
import LegacyCategories from './legacy-api/categories'
import LegacyComments from './legacy-api/comments'
import LegacyDeviceOpportunities from './legacy-api/deviceOpportunities'
import LegacyEdito from './legacy-api/edito'
import LegacyDigitalVirgo from './legacy-api/external/digitalVirgo'
import LegacyMe from './legacy-api/me'
import LegacyMediaTypes from './legacy-api/mediaTypes'
import LegacyRegistration from './legacy-api/registration'
import LegacySelections from './legacy-api/selections'
import LegacySeries from './legacy-api/series'
import LegacySessions from './legacy-api/sessions'
import LegacyShowrooms from './legacy-api/showrooms'
import LegacyTunnels from './legacy-api/tunnels'
import LegacyUsers from './legacy-api/users'
import LegacyRequest from './legacy-plugins/request'
import { Request } from './request'

const logger = consola.withScope('Hummingbird (Common)')

type NotImplementedClass = {
  [key: string]: any
}
const NotImplemented: NotImplementedClass = {}

export default class Hummingbird {
  public readonly legacyRequest: LegacyRequest
  public readonly accessTokens: LegacyAccessTokens | NotImplementedClass
  public readonly adyenSubscription: LegacyAdyenSubscription | AdyenSubscription

  public readonly authors: LegacyAuthors | NotImplementedClass
  public readonly bookLists: LegacyBookLists | NotImplementedClass
  public readonly booklistsBooks: LegacyBooklistsBooks | NotImplementedClass
  public readonly bookReadings: LegacyBookReadings | NotImplementedClass
  public readonly books: LegacyBooks | NotImplementedClass
  public readonly categories: LegacyCategories | NotImplementedClass
  public readonly comments: LegacyComments | NotImplementedClass
  public readonly digitalVirgo: LegacyDigitalVirgo | NotImplementedClass
  public readonly deviceOpportunities:
    | LegacyDeviceOpportunities
    | DeviceOpportunities

  public readonly edito: LegacyEdito | NotImplementedClass
  public readonly me: LegacyMe | Me
  public readonly mediaTypes: LegacyMediaTypes | NotImplementedClass
  public readonly orders: Orders
  public readonly receipts: Receipts
  public readonly profiles: Profiles
  public readonly registration: LegacyRegistration | Registration
  public readonly selections: LegacySelections | NotImplementedClass
  public readonly series: LegacySeries | NotImplementedClass
  public readonly sessions: LegacySessions | Sessions
  public readonly showrooms: LegacyShowrooms | NotImplementedClass
  public readonly tunnels: LegacyTunnels | NotImplementedClass
  public readonly users: LegacyUsers | Users
  private request: Request
  private country: string
  private readonly apiVersionRef = {
    value: 'legacy' as 'legacy' | 'modern',
  }

  constructor(
    legacyApiKey: string,
    legacyApiSecret: string,
    legacyBaseUrl: string,
    nextoryEndpoint: string,
    userAgent: string,
    localeIso: string
  ) {
    const { language, country } = Hummingbird.parseLocaleIso(localeIso)

    this.legacyRequest = new LegacyRequest(
      legacyApiKey,
      legacyApiSecret,
      legacyBaseUrl,
      userAgent,
      language,
      country
    )
    this.request = new Request(nextoryEndpoint, country, language)
    this.country = country

    this.accessTokens = this.createApiProxy(
      new LegacyAccessTokens(this.legacyRequest),
      NotImplemented
    )
    this.adyenSubscription = this.createApiProxy(
      new LegacyAdyenSubscription(this.legacyRequest),
      new AdyenSubscription(this.request)
    )
    this.authors = this.createApiProxy(
      new LegacyAuthors(this.legacyRequest),
      NotImplemented
    )
    this.bookLists = this.createApiProxy(
      new LegacyBookLists(this.legacyRequest),
      NotImplemented
    )
    this.booklistsBooks = this.createApiProxy(
      new LegacyBooklistsBooks(this.legacyRequest),
      NotImplemented
    )
    this.bookReadings = this.createApiProxy(
      new LegacyBookReadings(this.legacyRequest),
      NotImplemented
    )
    this.books = this.createApiProxy(
      new LegacyBooks(this.legacyRequest),
      NotImplemented
    )
    this.categories = this.createApiProxy(
      new LegacyCategories(this.legacyRequest),
      NotImplemented
    )
    this.comments = this.createApiProxy(
      new LegacyComments(this.legacyRequest),
      NotImplemented
    )
    this.digitalVirgo = this.createApiProxy(
      new LegacyDigitalVirgo(this.legacyRequest),
      NotImplemented
    )
    this.deviceOpportunities = this.createApiProxy(
      new LegacyDeviceOpportunities(this.legacyRequest),
      new DeviceOpportunities(this.request)
    )
    this.edito = this.createApiProxy(
      new LegacyEdito(this.legacyRequest),
      NotImplemented
    )
    this.me = this.createApiProxy(
      new LegacyMe(this.legacyRequest),
      new Me(this.request)
    )
    this.mediaTypes = this.createApiProxy(
      new LegacyMediaTypes(this.legacyRequest),
      NotImplemented
    )
    this.registration = this.createApiProxy(
      new LegacyRegistration(this.legacyRequest),
      new Registration(this.request)
    )
    this.selections = this.createApiProxy(
      new LegacySelections(this.legacyRequest),
      NotImplemented
    )
    this.series = this.createApiProxy(
      new LegacySeries(this.legacyRequest),
      NotImplemented
    )
    this.sessions = this.createApiProxy(
      new LegacySessions(this.legacyRequest),
      new Sessions(this.request)
    )
    this.showrooms = this.createApiProxy(
      new LegacyShowrooms(this.legacyRequest),
      NotImplemented
    )
    this.tunnels = this.createApiProxy(
      new LegacyTunnels(this.legacyRequest),
      NotImplemented
    )
    this.users = this.createApiProxy(
      new LegacyUsers(this.legacyRequest),
      new Users(this.request)
    )

    // NX5-only APIs
    this.profiles = new Profiles(this.request)
    this.orders = new Orders(this.request)
    this.receipts = new Receipts(this.request)
  }

  private createApiProxy(from: any, to: any) {
    return createApiProxy(this.apiVersionRef, from, to)
  }

  /**
   * Turns an iso (eg. en-US) into an object with language and country
   */
  private static parseLocaleIso(localeIso: string) {
    const [language, country] = localeIso.split('-')
    return { language, country }
  }

  /**
   * Set up by @nextory/auth
   *
   * @param $storage
   */
  setupDeviceId($storage: any) {
    this.setDeviceId(
      $storage.syncUniversal('deviceId', Hummingbird.createUUID())
    )
  }

  /**
   * Helper function to create a unique device id.
   *
   * @see https://gist.github.com/jsmithdev/1f31f9f3912d40f6b60bdc7e8098ee9f
   */
  private static createUUID() {
    let dt = new Date().getTime()

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
      }
    )
  }

  /**
   * In the format of language-COUNTRY, e.g. en-US, fr-FR, en-FR...
   */
  setLocaleIso(localeIso: string) {
    const { language, country } = Hummingbird.parseLocaleIso(localeIso)

    this.setLanguage(language)
    this.setCountry(country)
  }

  /**
   * Avoid using this method, you'll most likely want to use setLocaleIso()
   * @see setLocaleIso
   */
  setLanguage(language: string) {
    this.legacyRequest._setLanguage(language)
  }

  setCountry(country: string | undefined) {
    this.legacyRequest._setCountry(country)
  }

  setDeviceId(deviceId: string) {
    this.request._setDeviceId(deviceId)
  }

  setAuthToken(authToken: string) {
    this.legacyRequest._setAuthToken(authToken)
    this.request._setAuthToken(authToken)
  }

  setAuthorizationToken(authorizationToken: string) {
    this.legacyRequest._setAuthorizationToken(authorizationToken)
  }

  setLogLevel(logLevel: string | number) {
    this.legacyRequest._setLogLevel(
      typeof logLevel === 'string' ? parseInt(logLevel) : logLevel
    )
  }

  setApiKey(apiKey: string) {
    this.legacyRequest._setApiKey(apiKey)
  }

  setApiSecret(apiSecret: string) {
    this.legacyRequest._setApiSecret(apiSecret)
  }

  setUserCatalogIds(catalogId: number, virtualCatalogIds: number[]) {
    this.legacyRequest._setUserCatalogIds(catalogId, virtualCatalogIds)
  }

  setAdultFilter(adultFilter: boolean) {
    this.legacyRequest._setAdultFilter(adultFilter)
  }

  resetAuthorizationToken() {
    this.legacyRequest._resetAuthorizationToken()
  }

  /**
   * Shortcut to call `get` REST action on HTTP client
   *
   * @param {String} endpoint: Url of the request without baseURL => /api/selections
   * @param {Object} params: Parameters of the request
   */
  get(endpoint: string, params: object) {
    return this.legacyRequest.http.get(endpoint, params)
  }

  /**
   * Shortcut to call `post` REST action on HTTP client
   *
   * @param {String} endpoint: Url of the request without baseURL => /api/selections
   * @param {Object} params: Parameters of the request
   */
  post(endpoint: string, params: object) {
    return this.legacyRequest.http.post(endpoint, params)
  }

  /**
   * Shortcut to call `put` REST action on HTTP client
   *
   * @param {String} endpoint: Url of the request without baseURL => /api/selections/:id
   * @param {Object} params: Parameters of the request
   */
  put(endpoint: string, params: object) {
    return this.legacyRequest.http.put(endpoint, params)
  }

  /**
   * Shortcut to call `patch` REST action on HTTP client
   *
   * @param {String} endpoint: Url of the request without baseURL => /api/selections/:id
   * @param {Object} params: Parameters of the request
   */
  patch(endpoint: string, params: object) {
    return this.legacyRequest.http.patch(endpoint, params)
  }

  /**
   * Shortcut to call `delete` REST action on HTTP client
   *
   * @param {String} endpoint: Url of the request without baseURL => /api/selections/:id
   * @param {Object} params: Parameters of the request
   */
  delete(endpoint: string, params: object) {
    return this.legacyRequest.http.delete(endpoint, params)
  }

  setApiVersion(version: 'legacy' | 'modern') {
    if (!['legacy', 'modern'].includes(version)) {
      throw new Error(
        `Invalid API version: '${version}', should be 'legacy' or 'modern'`
      )
    }

    const previousVersion = this.apiVersionRef.value
    this.apiVersionRef.value = version

    if (this.apiVersionRef.value !== previousVersion) {
      // TODO: Disconnect user if switching from legacy to modern or vice versa
      logger.debug('Setting API version to', version)
    }
  }

  isUsingLegacyApi(): boolean {
    return this.apiVersionRef.value === 'legacy'
  }

  isUsingModernApi(): boolean {
    return this.apiVersionRef.value === 'modern'
  }

  get marketProperties(): MarketProperties {
    return merge(defaultProperties(), propertiesPerMarkets()[this.country])
  }
}
