import Hummingbird from '@nextory/hummingbird'

const DEFAULTS = {
  tokenRequired: true,
  globalToken: true,
}

/**
 * Custom connection scheme for youboox
 */
export default class NxScheme {
  private hummingbird: Hummingbird
  private $auth: any
  private readonly name: string
  private options: any

  constructor(auth: any, options: any) {
    this.$auth = auth
    this.name = options._name

    this.options = Object.assign({}, DEFAULTS, options)

    this.hummingbird = this.$auth.$storage._useVuex.$hummingbird

    this.hummingbird.setupDeviceId(this.$auth.$storage)
  }

  /**
   * Set X-API-AUTH-TOKEN in the hummingbird header
   *
   * @internal Used by Nuxt Auth, don't rename.
   */
  _setToken(
    token: string // yb api auth_token
  ) {
    if (this.options.globalToken) {
      // Set Authorization token for all hummingbird requests
      this.hummingbird.setAuthToken(token)
    }
  }

  /**
   * Remove X-API-AUTH-TOKEN in the hummingbird header
   *
   * @internal Used by Nuxt Auth, don't rename.
   */
  _clearToken() {
    if (this.options.globalToken) {
      // Clear Authorization token for all hummingbird requests
      this.hummingbird.setAuthToken('')
    }
  }

  /**
   * Call on each connexion to the website
   * Set token & get user information
   */
  mounted() {
    if (this.options.tokenRequired) {
      const token = this.$auth.syncToken(this.name)
      this._setToken(token)
    }

    return this.$auth.fetchUserOnce()
  }

  /**
   * Get api auth_token user information
   * @param data: Credentials
   */
  async login({ data }: { data: any }) {
    // Ditch any leftover local tokens before attempting to log in
    await this.$auth.reset()
    return this.hummingbird.sessions
      .login(data)
      .then(response => {
        const user = response.data

        if (this.options.tokenRequired) {
          const token = user.authentication_token

          this.hummingbird.setAuthToken(token)
          this.$auth.setToken(this.name, token)
          this._setToken(token)
        }

        this.$auth.setUser(user)
        this.hummingbird.setAdultFilter(user.is_adult_filtered)

        return user
      })
      .catch(e => {
        return Promise.reject(e)
      })
  }

  /**
   * Set token  to cache and hummingbird header
   */
  setUserToken(
    token: string // yb api auth_token
  ) {
    this.$auth.setToken(this.name, token)
    this._setToken(token)

    return this.fetchUser()
  }

  /**
   * Get user information from /me/profile
   * Set new auth_token after
   */
  async fetchUser() {
    // Token is required but not available
    if (this.options.tokenRequired && !this.$auth.getToken(this.name)) {
      return
    }

    const response = await this.hummingbird.me.getProfile()
    const user = response.data

    this.$auth.setUser(user)
    this.hummingbird.setAdultFilter(user.is_adult_filtered)

    const token = user.authentication_token
    // In NX5, token is not present in this response (but earlier, at login/registration), so we need to check if it's present
    if (token) {
      this.hummingbird.setAuthToken(token)
      this.$auth.setToken(this.name, token)
    }
  }

  /**
   * Reset auth cache/cookie and header
   */
  logout() {
    this.$auth.reset()
    return this.hummingbird.sessions
      .logout()
      .then(response => {
        return response
      })
      .catch(e => {
        return Promise.reject(e)
      })
  }

  reset() {
    this.$auth.setUser(false)
    this.$auth.setToken(this.name, false)
    this.$auth.setRefreshToken(this.name, false)

    return Promise.resolve()
  }
}
