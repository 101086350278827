import { AxiosPromise } from 'axios'

import { OnboardingGateway } from '../api-types/OnboardingGateway'
import { legacyOpportunitiesToModernOnboardingGateway } from '../bridge-api/converters/opportunitiesToModernOnboardingGateway'
import convert from '../bridge-api/helpers/convert'

import BaseApi from './baseApi'
import { TranslatedFeatureKey } from './types/deviceOpportunity'

export default class DeviceOpportunities extends BaseApi {
  getDeviceOpportunities(
    paramsModifier: TranslatedFeatureKey | undefined
  ): AxiosPromise<OnboardingGateway> {
    return convert(
      this.http({
        method: 'get',
        url: '/device_opportunities',
      }),
      legacyOpportunitiesToModernOnboardingGateway,
      paramsModifier
    )
  }
}
