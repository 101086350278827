import { AxiosPromise } from 'axios'

import { OnboardingGateway } from '../api-types/OnboardingGateway'
import LegacyDeviceOpportunities from '../legacy-api/deviceOpportunities'
import { LocaleParams } from '../legacy-api/types/common'

import BaseApi from './baseApi'

export class DeviceOpportunities
  extends BaseApi
  // partially implementing this lets us use TypeScript's type inference on bridge methods
  implements Partial<LegacyDeviceOpportunities>
{
  getDeviceOpportunities(): AxiosPromise<OnboardingGateway> {
    return this.http({
      method: 'get',
      url: '/obgateway/v1/subscriptions',
    })
  }
}
