import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { BookList } from './types/bookList'
import { PaginationParams } from './types/common'

export default class BookLists extends BaseApi {
  /**
   * Fetch all user's booklists
   */
  getBookLists(params: PaginationParams): AxiosPromise<BookList[]> {
    return this.http({
      method: 'get',
      url: '/me/book_lists',
      params,
    })
  }

  getBookList(type: number): AxiosPromise<BookList> {
    return this.http({
      method: 'get',
      url: `/me/book_lists/${type}`,
    })
  }

  /**
   * Action that create a new booklist
   */
  createList(data: {
    name: string // booklist name
    user_device_name?: string // name of the user's device
  }): AxiosPromise<BookList> {
    return this.http({
      method: 'post',
      url: '/me/book_lists',
      data: {
        book_list: data,
      },
    })
  }

  /**
   * Action that update an existing booklist
   */
  updateList(
    booklistId: number,
    data: {
      name: string
    }
  ): AxiosPromise<BookList> {
    return this.http({
      method: 'patch',
      url: `/me/book_lists/${booklistId}`,
      data: {
        book_list: data,
      },
    })
  }

  /**
   * Action that delete a booklist
   */
  removeList(booklistId: number) {
    return this.http({
      method: 'delete',
      url: `/me/book_lists/${booklistId}`,
    })
  }
}
