import Hummingbird from '@nextory/hummingbird'
import { Plugin, Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import consola from 'consola'

const logger = consola.withScope('Hummingbird plugin')

const nxHummingbirdPlugin: Plugin = (
  { app: { $config, i18n } }: Context,
  inject: Inject
) => {
  if (
    [
      $config.X_API_KEY,
      $config.X_API_SECRET,
      $config.API_BASE_URL,
      $config.NEXTORY_API_ENDPOINT,
      $config.USER_AGENT,
      $config.CONSOLA_LEVEL,
      i18n.localeProperties.iso ?? undefined,
      i18n.localeProperties.api ?? undefined,
    ].includes(undefined)
  ) {
    logger.error('Missing configuration for Hummingbird, got:', {
      X_API_KEY: $config.X_API_KEY,
      X_API_SECRET: $config.X_API_SECRET,
      API_BASE_URL: $config.API_BASE_URL,
      NEXTORY_API_ENDPOINT: $config.NEXTORY_API_ENDPOINT,
      USER_AGENT: $config.USER_AGENT,
      CONSOLA_LEVEL: $config.CONSOLA_LEVEL,
      'i18n.localeProperties.iso': i18n.localeProperties.iso ?? undefined,
      'i18n.localeProperties.api': i18n.localeProperties.api ?? undefined,
    })
  }

  const hummingbirdClient = new Hummingbird(
    $config.X_API_KEY,
    $config.X_API_SECRET,
    $config.API_BASE_URL,
    $config.NEXTORY_API_ENDPOINT,
    $config.USER_AGENT,
    i18n.localeProperties.iso
  )

  hummingbirdClient.setLogLevel($config.CONSOLA_LEVEL)
  hummingbirdClient.setApiVersion(i18n.localeProperties.api)

  inject('hummingbird', hummingbirdClient)
}

export default nxHummingbirdPlugin
