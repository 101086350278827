import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'

export default class AccessTokens extends BaseApi {
  /**
   * Ask for access token
   */
  ask(data: {
    callback_url: string // url to redirect user after successful provider authentication
    provider: 'free' // provider related to access_token
  }): AxiosPromise<{
    access_token: string
  }> {
    return this.http({
      method: 'post',
      url: `/access_tokens`,
      data: { access_token: data },
    })
  }
}
