import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { Book } from './types/book'
import { Category } from './types/category'
import { LanguagesParams, PaginationParams } from './types/common'

export default class Categories extends BaseApi {
  getCategory(id: number): AxiosPromise<Category> {
    return this.send('get', `/categories/${id}`)
  }

  getCategoryBooks(
    categoryId: number,
    params: PaginationParams & LanguagesParams
  ): AxiosPromise<Book[]> {
    return this.send('get', `/categories/${categoryId}/books`, params)
  }
}
