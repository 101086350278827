import { AxiosPromise } from 'axios'

import { Receipt } from '../api-types/Receipt'
import BaseApi from '../bridge-api/baseApi'

export class Receipts extends BaseApi {
  downloadReceipt(orderId: number): AxiosPromise<Receipt[]> {
    return this.http({
      method: 'get',
      responseType: 'blob',
      url: `/order/v1/me/order/${orderId}`,
    })
  }
}
