export interface MarketProperties {
  img: {
    homepage: {
      hero_desktop: string
      hero_mobile: string
      hero_tablet: string
      more_about_1: string
      more_about_2: string
      more_about_3: string
      phone_cover: string
    }
  }
  minimumPrice: {
    amount: number
    currency: string
  }
  trialDays: number
}

/**
 * Defaults are for French market. Cocorico!
 *
 * On nx-web-legacy, only FR and BE are available.
 * Check the same file on nx-web for the full thing.
 */
export function defaultProperties(): MarketProperties {
  return {
    trialDays: 14,
    img: {
      homepage: {
        hero_desktop: 'nx-images/home/hero/global/hero-offer-desktop.jpg',
        hero_mobile: 'nx-images/home/hero/global/hero-offer-mobile.jpeg',
        hero_tablet: 'nx-images/home/hero/global/hero-offer-tablet.jpeg',
        more_about_1: 'nx-images/home/more-about-nx/fr/1-FR.jpg',
        more_about_2: 'nx-images/home/more-about-nx/fr/2-FR.jpg',
        more_about_3: 'nx-images/home/more-about-nx/fr/3-FR.jpg',
        phone_cover: 'nx-images/home/phone/fr/phonebookcovers_FR.jpg',
      },
    },
    minimumPrice: { amount: 9.99, currency: 'EUR' },
  }
}

/**
 * Only set market-specific data here, the rest will be filled with the defaults automatically in Hummingbird.
 */
export function propertiesPerMarkets(): Record<
  string,
  Partial<MarketProperties>
> {
  return {
    BE: {
      // Most things are identical to FR
    },
    FR: {
      /** @see defaultProperties() */
    },
  }
}
