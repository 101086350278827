import { AxiosPromise } from 'axios'

import {
  EditableProfile,
  ProfilesResponse,
  Profile,
} from '../api-types/Profile'
import BaseApi from '../bridge-api/baseApi'

export class Profiles extends BaseApi {
  /**
   * @see https://nextory.atlassian.net/wiki/spaces/NEX/pages/2062778374/User+API+Spec#1.0.5-List-Profiles
   */
  getProfiles(): AxiosPromise<ProfilesResponse> {
    return this.http({
      method: 'get',
      url: `/user/v1/me/profiles`,
    })
  }

  /**
   * Helper function to find a profile by id.
   */
  getProfileById(id: string): Promise<Profile> {
    return new Promise((resolve, reject) => {
      this.getProfiles()
        .then(({ data: { profiles } }) => {
          const profile: Profile | undefined = profiles.find(
            p => p.id.toString() === id
          )

          if (profile === undefined) {
            throw new Error('Profile not found')
          }

          resolve(profile)
        })
        .catch(e => reject(e))
    })
  }

  /**
   * @see https://nextory.atlassian.net/wiki/spaces/NEX/pages/2062778374/User+API+Spec#1.0.6-Add-Profile
   */
  addProfile(data: EditableProfile): AxiosPromise<any> {
    return this.http({
      method: 'post',
      url: `/user/v1/me/profile`,
      data,
    })
  }

  /**
   * @see https://nextory.atlassian.net/wiki/spaces/NEX/pages/2062778374/User+API+Spec#1.0.7-Update-Profile
   */
  updateProfile(data: EditableProfile): AxiosPromise<any> {
    return this.http({
      method: 'patch',
      url: `/user/v1/me/profile`,
      data,
    })
  }

  /**
   * @see https://nextory.atlassian.net/wiki/spaces/NEX/pages/2062778374/User+API+Spec#1.0.8-Delete-Profile
   */
  deleteProfile(loginKey: string): AxiosPromise<any> {
    return this.http({
      method: 'delete',
      url: `/user/v1/me/profile`,
      data: {
        login_key: loginKey,
      },
    })
  }
}
