//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CrossLink from './CrossLink.vue'
import HeaderNavigation from './header/Navigation.vue'

export default {
  components: {
    HeaderNavigation,
    CrossLink,
  },

  props: {
    hasNavIconsLight: { type: Boolean, default: false },
    isTransparent: { type: Boolean, default: false },
    isOverlaid: { type: Boolean, default: false },
  },

  // TODO Show again when search will be active
  // data() {
  //   return {
  //     search: '',
  //     focusedClass: '',
  //     isFocused: false,
  //   }
  // },

  // computed: {
  //   isResetable() {
  //     return this.search !== ''
  //   },
  // },

  // methods: {
  //   handleSearch() {
  //     /* eslint-disable no-console */
  //     console.log(`Search for: ${this.search}`)
  //   },
  //   resetSearch() {
  //     this.search = ''
  //     this.$refs.search.focus()
  //   },
  //   focusedSearchClass() {
  //     this.focusedClass = 'search-form--focused'
  //     this.isFocused = true
  //   },
  //   unfocusedSearchClass() {
  //     this.focusedClass = ''
  //     this.search = ''
  //     this.isFocused = false
  //   },
  // },
}
