/**
 * Don't include this file directly, unless you cannot use `$link`.
 */
const webReaderUrl = process.env.NEXTORY_WEB_READER_URL

export default {
  contactEmail: 'serviceclient@nextory.com',
  downloadAppleStore: 'https://apps.apple.com/fr/app/id480514565',
  downloadGooglePlayStore:
    'https://play.google.com/store/apps/details?id=fr.youboox.mobile',
  downloadAppleStoreNx5: 'https://apps.apple.com/us/app/nextory/id993578896',
  downloadGooglePlayStoreNx5:
    'https://play.google.com/store/apps/details?id=com.gtl.nextory',
  editorAuthorLogin: 'https://app.youboox.fr/fr/editors/sign_in',
  investorsUrl: 'https://investors.nextory.com/',
  publisherLogin: 'https://app.youboox.fr/fr/agents/sign_in',
  partnerWithUs: 'https://nextory.com/fr/collab/contact/fr/',
  webReaderUrl,
}
