import { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import { Book } from './types/book'
import { PaginationParams } from './types/common'
import { Series as SeriesResponse } from './types/series'

export default class Series extends BaseApi {
  /**
   * Action that retrieve a series
   */
  fetchSeries(seriesId: number): AxiosPromise<SeriesResponse> {
    return this.send('get', `/series/${seriesId}`)
  }

  /**
   * Action that retrieve a series' books
   */
  fetchSeriesBooks(
    seriesId: number,
    params: PaginationParams
  ): AxiosPromise<Book[]> {
    return this.send('get', `/series/${seriesId}/books`, params)
  }
}
